import React, {useEffect, useState} from 'react';
import { Table } from 'semantic-ui-react';
import Select from 'react-select';

import api from "../../../services/api";

import './styles.css'
import "../../../components/table.css";




export default function TabelaProdutosProdutores () {
    //Base de dados
    const [produtos, setProdutos] = useState([]);
    const [produtores, setProdutores] = useState([]);
    const [produtosProdutores, setProdutosProdutores] = useState([]);

    //Nova Entrada
    const [novoProduto, setNovoProduto] = useState({label: 'Novo Produto'});
    const [novoProdutor, setNovoProdutor] = useState('Selecione um produtor');

    //Rerenderizar
    const [restart, setRestart] = useState(false);




    //Use Effect
    useEffect(() => {
    api.get('./produtos', {})
        .then(response => {
            setProdutos(response.data);
        })
    api.get('./produtores', {})
        .then(response => {
            setProdutores(response.data);
        })
    api.get('./produtosprodutores', {})
        .then(response => {
            setProdutosProdutores(response.data);
        })
    }, [restart]);


    //Functions
    async function handleDeleteProdutoProdutor(id){

        try {
            await api.delete(`produtosprodutores/${id}`, {

            });

            setProdutosProdutores(produtosProdutores.filter(produtoProdutor => produtoProdutor._id !== id));
        } catch (id) {
            alert(id)
        }
    }

    async function handleCreateProdutoProdutor(e){
        e.preventDefault();

        try {
            await api.post(`produtosprodutores`, {
                "produto": novoProduto.value,
                "produtor": novoProdutor.value,
            }, {

            });

        } catch (e) {
            alert("Erro: Esse produto já existe na horta do produtor")
        }

        setNovoProduto({label: 'Novo produto'});
        setRestart(!restart);
    }




    return(
        <div>
            <h1>Administrar os Produtos {novoProdutor === 'Selecione um produtor' ? '': `de ${novoProdutor.label}`}</h1>
            <Select className="produtor-produtosprodutores"
                 options = {produtores.map(produtor => (
                     {value: produtor._id, label: produtor.produtor}
                 ))}
                 placeholder={novoProdutor}
                 value={novoProdutor}
                 onChange={val => setNovoProdutor(val)}
            />
            {novoProdutor === 'Selecione um produtor'? '':
                <Table compact celled definition className="produto-produtor-table">
                    <Table.Header className="produto-produtor-tableheader">
                        <Table.Row className="produto-produtor-row">
                            <Table.HeaderCell className="produto-produtor-headercell">
                                Associar Produto
                            </Table.HeaderCell>
                            <Table.HeaderCell className="produto-produtor-headercell">
                                 <Select className="produto-categoria-criar"
                                     options = {produtos.map(produto => (
                                         {value: produto._id, label: produto.produto}
                                     ))}
                                     placeholder={novoProduto.label}
                                     value={novoProduto}
                                     onChange={val => setNovoProduto(val)}
                                />
                            </Table.HeaderCell>
                            <button className="criar-produto-produtor" onClick={handleCreateProdutoProdutor}>
                                <Table.HeaderCell className="produto-produtor-headercell">
                                    Enviar Produto
                                </Table.HeaderCell>
                            </button>
                        </Table.Row>
                    </Table.Header>

                    <Table.Header className="produto-produtor-tableheader">
                        <Table.Row className="produto-produtor-row">
                            <Table.HeaderCell className="produto-headercell">ID</Table.HeaderCell>
                            <Table.HeaderCell className="produto-headercell">Produto</Table.HeaderCell>
                            <Table.HeaderCell className="produto-headercell">Descrição</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body className="produto-tablebody">
                        {produtosProdutores
                            .filter(produtosProdutores => produtosProdutores.produtor._id === novoProdutor.value)
                            .map(produtoProdutor =>
                                <Table.Row className="produto-produtor-row" key={produtoProdutor?._id}>
                                    <Table.Cell className="produto-produtor-cell">{produtoProdutor?._id}</Table.Cell>
                                    <Table.Cell className="produto-produtor-cell">
                                        {produtoProdutor?.produto?.produto} de {novoProdutor?.label}
                                    </Table.Cell>
                                    <Table.Cell className="produto-cell">
                                            {produtoProdutor?.produto?.categoria?.categoria} vendidas(os) em porções de {produtoProdutor?.produto?.quantia} {produtoProdutor?.produto?.unidade?.unidade}.
                                        <br/>
                                            {produtoProdutor?.produto?.descricao}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <button onClick={() => handleDeleteProdutoProdutor(produtoProdutor._id)} className="deletar-produto-produtor">
                                            Deletar
                                        </button>
                                    </Table.Cell>
                                </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            }
        </div>
    );
}