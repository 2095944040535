import React, {useEffect} from "react";

export default function PortuguesePolicy() {

    useEffect(() => {
        document.title = "Política de Privacidade - Rede Tamanduá"
    },[])

    return(
        <div>
                <h1> Política de Privacidade da Rede Tamanduá de Prossumidores Agroecológicos </h1>

                <p> Na Rede Prossumidores, acessível em https://frontend-redeprossumidores.herokuapp.com/, uma das nossas principais prioridades é a privacidade de nossos visitantes. Este documento da Política de Privacidade contém tipos de informações que são coletadas e registradas pela Rede Prossumidores e como as usamos. </p>

                <p> Se você tiver dúvidas adicionais ou precisar de mais informações sobre nossa Política de Privacidade, não hesite em nos contactar. </p>

                <p> Esta Política de Privacidade aplica-se apenas às nossas atividades online e é válida para os visitantes do nosso site com relação às informações que eles compartilharam e / ou coletam na Rede Prossumidores. Esta política não é aplicável a nenhuma informação coletada offline ou por outros canais que não este site. </p>

                <h2> Consentimento </h2>

                <p> Ao usar nosso site, você concorda com nossa Política de Privacidade e concorda com seus termos. </p>

                <h2> Informações que coletamos </h2>

                <p> As informações pessoais solicitadas por você e os motivos pelos quais você as solicita serão esclarecidas no momento em que solicitarmos que você forneça suas informações pessoais. </p>
                <p> Se você entrar em contato conosco diretamente, podemos receber informações adicionais sobre você, como seu nome, endereço de e-mail, número de telefone, o conteúdo da mensagem e / ou anexos que você pode nos enviar e qualquer outra informação que você escolher fornecer . </p>
                <p> Quando você se registra para uma conta, podemos solicitar suas informações de contato, incluindo itens como nome, nome da empresa, endereço, endereço de e-mail e número de telefone. </p>

                <h2> Como usamos suas informações </h2>

                <p> Usamos as informações que coletamos de várias maneiras, incluindo: </p>

                <ul>
                        <li> Fornecer, operar e manter nosso webste </li>
                        <li> Melhorar, personalizar e expandir nosso webste </li>
                        <li> Entenda e analise como você usa nosso webste </li>
                        <li> Desenvolver novos produtos, serviços, recursos e funcionalidades </li>
                        <li> Comunique-se com você, diretamente ou através de um de nossos parceiros, inclusive para atendimento ao cliente, para fornecer atualizações e outras informações relacionadas ao webste e para fins de marketing e promoção </li>
                        <li> Enviar e-mails </li>
                        <li> Encontre e evite fraudes </li>
                </ul>

                <h2> Arquivos de log </h2>

                <p> A Rede Prossumidores segue um procedimento padrão de uso de arquivos de log. Esses arquivos registram os visitantes quando eles visitam sites. Todas as empresas de hospedagem fazem isso e fazem parte da análise dos serviços de hospedagem. As informações coletadas pelos arquivos de log incluem endereços de protocolo da Internet (IP), tipo de navegador, provedor de serviços de Internet (ISP), carimbo de data e hora, páginas de referência / saída e, possivelmente, o número de cliques. Estes não estão ligados a nenhuma informação que seja pessoalmente identificável. O objetivo das informações é analisar tendências, administrar o site, rastrear o movimento dos usuários no site e coletar informações demográficas. Nossa Política de Privacidade foi criada com a ajuda do <a href="https://www.privacypolicygenerator.info"> Gerador de Política de Privacidade </a> e do <a href = "https://www.privacypolicytemplate.net/ "> Modelo de política de privacidade </a>. </p>

                <h2> Cookies e web beacons </h2>

                <p> Como qualquer outro site, a Rede Prossumidores usa 'cookies'. Esses cookies são usados ​​para armazenar informações, incluindo as preferências dos visitantes, e as páginas no site que o visitante acessou ou visitou. As informações são usadas para otimizar a experiência dos usuários, personalizando o conteúdo de nossa página da web com base no tipo de navegador dos visitantes e / ou em outras informações. </p>

                <p> Para informações mais gerais sobre cookies, leia <a href="https://www.cookieconsent.com/what-are-cookies/"> "O que são cookies" </a>. </p>



                <h2> Políticas de privacidade de parceiros de publicidade </h2>

                <p> Você pode consultar esta lista para encontrar a Política de Privacidade de cada um dos parceiros de publicidade da Rede Prossumidores. </p>

                <p> Os servidores ou redes de anúncios de terceiros usam tecnologias como cookies, JavaScript ou Web Beacons usados ​​em seus respectivos anúncios e links que aparecem na Rede Prossumidores, que são enviados diretamente ao navegador dos usuários. Eles recebem automaticamente seu endereço IP quando isso ocorre. Essas tecnologias são usadas para medir a eficácia de suas campanhas publicitárias e / ou para personalizar o conteúdo de publicidade exibido nos sites que você visita. </p>

                <p> Observe que a Rede Prossumidores não tem acesso ou controle sobre esses cookies usados ​​por anunciantes terceiros. </p>

                <h2> Políticas de privacidade de terceiros </h2>

                <p> A Política de Privacidade da Rede Prossumidores não se aplica a outros anunciantes ou sites. Portanto, recomendamos que você consulte as respectivas Políticas de Privacidade desses servidores de anúncios de terceiros para obter informações mais detalhadas. Pode incluir suas práticas e instruções sobre como desativar determinadas opções. Você pode encontrar uma lista completa dessas políticas de privacidade e seus links aqui: Links da política de privacidade. </p>

                                <p> Você pode optar por desativar os cookies através das opções individuais do seu navegador. Para conhecer informações mais detalhadas sobre o gerenciamento de cookies com navegadores da web específicos, ele pode ser encontrado nos respectivos sites. O que são cookies? </p>

                <h2> Direitos de privacidade da CCPA (não venda minhas informações pessoais) </h2>

                <p> De acordo com a CCPA, entre outros direitos, os consumidores da Califórnia têm o direito de: </p>
                <p> Solicite que uma empresa que coleta dados pessoais de um consumidor divulgue as categorias e partes específicas de dados pessoais que uma empresa coletou sobre os consumidores. </p>
                <p> Solicite que uma empresa exclua quaisquer dados pessoais sobre o consumidor que ela coletou. </p>
                <p> Solicite que uma empresa que venda dados pessoais de um consumidor, não venda os dados pessoais do consumidor. </p>
                <p> Se você fizer uma solicitação, temos um mês para responder a você. Se você deseja exercer algum desses direitos, entre em contato. </p>

                <h2> Direitos de proteção de dados do GDPR </h2>

                <p> Gostaríamos de garantir que você esteja totalmente ciente de todos os seus direitos de proteção de dados. Todo usuário tem direito ao seguinte: </p>
                <p> O direito de acesso - Você tem o direito de solicitar cópias de seus dados pessoais. Podemos cobrar uma pequena taxa por esse serviço. </p>
                <p> O direito de retificação - Você tem o direito de solicitar que corrijamos qualquer informação que considere imprecisa. Você também tem o direito de solicitar que completemos as informações que você acha incompletas. </p>
                <p> O direito de apagar - Você tem o direito de solicitar que apagemos seus dados pessoais, sob certas condições. </p>
                <p> O direito de restringir o processamento - Você tem o direito de solicitar que restrinjam o processamento de seus dados pessoais, sob certas condições. </p>
                <p> O direito de se opor ao processamento - Você tem o direito de se opor ao nosso processamento de seus dados pessoais, sob certas condições. </p>
                <p> O direito à portabilidade de dados - Você tem o direito de solicitar que transferamos os dados que coletamos para outra organização, ou diretamente para você, sob certas condições. </p>
                <p> Se você fizer uma solicitação, temos um mês para responder a você. Se você deseja exercer algum desses direitos, entre em contato. </p>

                <h2> Informações para crianças </h2>

                <p> Outra parte da nossa prioridade é adicionar proteção para crianças enquanto estiver usando a Internet. Incentivamos os pais e responsáveis ​​a observar, participar e / ou monitorar e orientar suas atividades on-line. </p>

                <p> A Rede Prossumidores não coleta intencionalmente quaisquer Informações Pessoais Identificáveis ​​de crianças menores de 13 anos. Se você acha que seu filho forneceu esse tipo de informação em nosso site, recomendamos que você entre em contato imediatamente e faremos o melhor possível. esforços para remover prontamente essas informações de nossos registros. </p>
        </div>
    );
}