import React, {useEffect, useState} from 'react';
import { Table, Modal } from 'semantic-ui-react';
import { Skeleton } from '@material-ui/lab';

import api from "../../../services/api";

import './styles.css'
import "../../../components/table.css";




export default function TabelaTaxas () {
    //Base de dados
    const [taxas, setTaxas] = useState([]);

    //Nova Entrada
    const [novaValor, setNovaValor] = useState([]);
    const [novaUnidade, setNovaUnidade] = useState([]);
    const [novoTaxa, setNovoTaxa] = useState([]);

    //Corrigir
    const [corrigirValor, setCorrigirValor] = useState([]);
    const [corrigirUnidade, setCorrigirUnidade] = useState([]);
    const [corrigirTaxa, setCorrigirTaxa] = useState([]);
    const [idAtual, setIdAtual] = useState([]);
    const [mostrar, setMostrar] = useState(false);

    //Rerenderizar
    const [restart, setRestart] = useState(false);




    //Use Effect
    useEffect(() => {
    api.get('./taxas', {})
        .then(response => {
            setTaxas(response.data);
        })
    }, [restart]);




    //Functions
    async function handleDeleteTaxa(id){

        try {
            await api.delete(`taxas/${id}`, {

            });

            setTaxas(taxas.filter(taxa => taxa._id !== id));
        } catch (id) {
            alert(id)
        }
    }

    async function handleRenomearTaxa(id){

        try {
            await api.put(`taxas/${id}`, {
                "taxa": corrigirTaxa,
                "valor": corrigirValor,
                "unidade": corrigirUnidade,
            });

        } catch (e) {
            alert("Erro:" + e)
        }

        setMostrar(false);
        setIdAtual([]);
        setCorrigirTaxa([]);
        setCorrigirValor([]);
        setCorrigirUnidade([]);
        setRestart(!restart);
    }

    async function handleCreateTaxa(e){
        e.preventDefault();

        try {
            await api.post(`taxas`, {
                "taxa": novoTaxa,
                "valor": novaValor,
                "unidade": novaUnidade,
            }, {

            });

        } catch (e) {
            alert("É necessário preencher todos os itens e todos precisam de estarem na forma correta (ex. valor é um número).")
        }

        setNovoTaxa([]);
        setNovaUnidade([]);
        setNovaValor([]);
        setRestart(!restart);
    }




    return(
        <Table compact celled definition className="taxa-table">
            <Table.Header className="taxa-tableheader">
                <Table.Row className="taxa-row">
                    <Table.HeaderCell className="taxa-headercell">
                            Criar Taxa
                    </Table.HeaderCell>
                    <Table.HeaderCell className="taxa-headercell">
                        <input
                            placeholder='Criar novo taxa...'
                            value={novoTaxa}
                            onChange={e => setNovoTaxa(e.target.value)}
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell className="taxa-headercell-valor">
                        <input
                            placeholder='Quanto em uma unidade...'
                            value={novaValor}
                            onChange={e => setNovaValor(e.target.value)}
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell className="taxa-headercell">
                         <input
                            placeholder='Quanto em uma unidade...'
                            value={novaUnidade}
                            onChange={e => setNovaUnidade(e.target.value)}
                        />
                    </Table.HeaderCell>
                    <button className="criar-taxa" onClick={handleCreateTaxa}>
                        <Table.HeaderCell className="taxa-headercell">
                            Enviar Taxa
                        </Table.HeaderCell>
                    </button>
              </Table.Row>
            </Table.Header>




            <Table.Header className="taxa-tableheader">
                <Table.Row className="taxa-row">
                    <Table.HeaderCell className="taxa-headercell">ID</Table.HeaderCell>
                    <Table.HeaderCell className="taxa-headercell">Taxa</Table.HeaderCell>
                    <Table.HeaderCell className="taxa-headercell-valor">Valor</Table.HeaderCell>
                    <Table.HeaderCell className="taxa-headercell">Unidade</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body className="taxa-tablebody">
                {taxas.map(taxa => (
                    <Table.Row className="taxa-row">
                        {!(taxa?._id === undefined) ?
                            <Table.Cell className="taxa-cell">{taxa._id}</Table.Cell>
                            :
                            <Table.Cell className="taxa-cell"><Skeleton width={100} height={50} animation="wave"/></Table.Cell>
                        }
                        {!(taxa?.taxa === undefined) ?
                            <Table.Cell className="taxa-cell">{taxa.taxa}</Table.Cell>
                            :
                            <Table.Cell className="taxa-cell"><Skeleton width={100} height={50} animation="wave"/></Table.Cell>
                        }
                        {!(taxa?.valor === undefined) ?
                            <Table.Cell className="taxa-cell-valor">{taxa.valor}</Table.Cell>
                            :
                            <Table.Cell className="taxa-cell"><Skeleton width={100} height={50} animation="wave"/></Table.Cell>
                        }
                        {!(taxa?.unidade === undefined) ?
                            <Table.Cell className="taxa-cell">{taxa.unidade}</Table.Cell>
                            :
                            <Table.Cell className="taxa-cell"><Skeleton width={100} height={50} animation="wave"/></Table.Cell>
                        }
                        {/*<Table.Cell>
                            <button onClick={() => handleDeleteTaxa(taxa._id)} className="deletar-taxa">
                                Deletar
                            </button>
                        </Table.Cell>*/}
                        <Table.Cell className="taxa-cell">
                            <button onClick={() => {
                                setIdAtual(taxa._id);
                                setMostrar(true);
                                setCorrigirTaxa(taxa.taxa);
                                setCorrigirValor(taxa.valor);
                                setCorrigirUnidade(taxa.unidade);
                            }} className="renomear-taxa">
                                Corrigir
                            </button>
                        </Table.Cell>
                    </Table.Row>
                ))}
                {mostrar && <div className="modaloverlay"/>}
                <Modal className="modal"
                  open={mostrar}
                  closeOnEscape={true}
                  closeOnDimmerClick={false}
                  //onClose={}
                >
                  <Table.Header className="taxa-tableheader">
                      <h2>Corrigir Taxa com ID: {idAtual}</h2>
                    <Table.Row className="taxa-row">
                        <Table.HeaderCell className="taxa-headercell">

                        </Table.HeaderCell>
                        <Table.HeaderCell className="taxa-headercell">
                            Taxa:
                            <input
                                placeholder={corrigirTaxa}
                                value={corrigirTaxa}
                                onChange={e => setCorrigirTaxa(e.target.value)}
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell className="taxa-headercell-valor">
                            Valor:
                            <input className="taxa-input-valor"
                                placeholder={corrigirValor}
                                value={corrigirValor}
                                onChange={e => setCorrigirValor(e.target.value)}
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell className="taxa-headercell-valor">
                            Unidade:
                            <input className="taxa-input-valor"
                                placeholder={corrigirUnidade}
                                value={corrigirUnidade}
                                onChange={e => setCorrigirUnidade(e.target.value)}
                            />
                        </Table.HeaderCell>
                        <button className="corrigir-taxa" onClick={() => handleRenomearTaxa(idAtual)}>
                            <Table.HeaderCell className="taxa-headercell">
                                Enviar Correção
                            </Table.HeaderCell>
                        </button>
                        <button className="cancelar-correcao-taxa"
                                onClick={() => {
                                    setMostrar(false);
                                    setIdAtual([]);
                                    setCorrigirTaxa([]);
                                    setCorrigirValor([]);
                                    setCorrigirUnidade([]);
                                }}
                        >
                            <Table.HeaderCell className="taxa-headercell">
                                Cancelar
                            </Table.HeaderCell>
                        </button>
                    </Table.Row>
                  </Table.Header>
                </Modal>
            </Table.Body>
        </Table>
    );
}