import React, {useEffect, useState} from 'react'
import { Table } from 'semantic-ui-react';
import "../../../components/table.css";
import api from "../../../services/api";

export default function TabelaUnidades () {
    const [unidades, setUnidades] = useState([]);
    const [nome, setNome] = useState([]);
    const [renomear, setRenomear] = useState([]);
    const [idAtual, setIdAtual] = useState([]);
    const [mostrar, setMostrar] = useState(false);
    const [restart, setRestart] = useState(false);

    useEffect(() => {
    api.get('./unidades', {})
        .then(response => {
            setUnidades(response.data)
        })
    }, [restart]);


    async function handleDeleteUnidade(id){

        try {
            await api.delete(`unidades/${id}`, {

            });

            setUnidades(unidades.filter(unidade => unidade._id !== id));
        } catch (id) {
            alert(id)
        }
    }

    async function handleRenomearUnidade(id){

        if (renomear === {}) {
            alert("Precisa digitar um nome corrigido pra unidade")
        }

        try {
            await api.put(`unidades/${id}`, {
                "unidade": renomear,
            });

            setRenomear([]);
            setRestart(!restart);
        } catch (e) {
            alert("Precisa digitar um nome corrigido pra unidade")
        }
    }

    async function handleCreateUnidade(e){
        e.preventDefault();

        try {
            await api.post(`unidades`, {
                "unidade": nome,
            }, {

            });

            setNome([]);
            setRestart(!restart);

        } catch (e) {
            alert("Precisa digitar um nome pra unidade nova")
        }
    }

    return(
        <Table compact celled definition className="unidade-table">
            <Table.Header className="unidade-tableheader">
                <Table.Row className="unidade-row">
                    <Table.HeaderCell className="unidade-headercell">
                            Criar Unidade
                    </Table.HeaderCell>
                    <Table.HeaderCell className="unidade-headercell">
                        <input
                            placeholder='Criar nova unidade...'
                            value={nome}
                            onChange={e => setNome(e.target.value)}
                        />
                    </Table.HeaderCell>
                    <button className="criar-unidade" onClick={handleCreateUnidade}>
                        <Table.HeaderCell className="unidade-headercell">
                            Enviar Unidade
                        </Table.HeaderCell>
                    </button>
              </Table.Row>
            </Table.Header>

            <Table.Header className="unidade-tableheader">
                <Table.Row className="unidade-row">
                    <Table.HeaderCell className="unidade-headercell">ID</Table.HeaderCell>
                    <Table.HeaderCell className="unidade-headercell">Unidade</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body className="unidade-tablebody">
                {unidades.map(unidade => (
                    <Table.Row className="unidade-row">
                        <Table.Cell className="unidade-cell">{unidade._id}</Table.Cell>
                        <Table.Cell className="unidade-cell">{unidade.unidade}</Table.Cell>
                        <Table.Cell>
                            <button onClick={() => handleDeleteUnidade(unidade._id)} className="deletar-unidade">
                                Deletar
                            </button>
                        </Table.Cell>
                        <Table.Cell className="unidade-cell">
                            <button onClick={() => {
                                setIdAtual(unidade._id);
                                setMostrar(!mostrar);
                                setRenomear([]);
                            }} className="renomear-unidade">
                                Corrigir
                            </button>
                        </Table.Cell>
                        { mostrar && (idAtual === unidade._id) ?
                            <Table.Cell className="caixa-renomear-unidade">
                                <input
                                    placeholder='Corrigir unidade...'
                                    value={renomear}
                                    onChange={e => setRenomear(e.target.value)}
                                />
                                <button onClick={() => handleRenomearUnidade(unidade._id)} className="enviar-renomear-unidade">
                                    Enviar
                                </button>
                            </Table.Cell>: ''
                        }
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
}
