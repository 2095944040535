import React, { Fragment, useState, useEffect } from 'react';

//Para trabalhar com as datas
import DatePicker, { registerLocale } from "react-datepicker/es";
import pt from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

//Para trabalhar com a tabela
import {Card, Menu, Modal, Popup, Table} from 'semantic-ui-react';
//import '../../../components/table.css';

//Para trabalhar com o dropdown
import Select from 'react-select';

//Para conectar com o backend
import api from '../../services/api';

import './styles.css';
import ResumoProssumidores from "./ResumoProssumidores";
import FazerPedidos from "../Pedido/FazerPedidos";

//material
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {set} from "react-ga";

registerLocale("pt-BR", pt);

export default function TabelaSemana () {
    //Trabalhar datas
    const [startDate, setStartDate] = useState(null);
    const [dia, setDia] = useState([]);
    const [diaJSON, setDiaJSON] = useState(null);
    const [comprado, setComprado] =useState([]);
    const [resumirEstoque, setResumirEstoque] = useState([]);
    const [dadosTrabalhados, setDadosTrabalhados] = useState([]);
    const [dadosReserva, setDadosReserva] = useState([]);

    //Menu
    const [mostrarTabela, setMostrarTabela] = useState(false);
    const [mostrarResumo, setMostrarResumo] = useState(false);

    //Corrigir Dados
    const [mostrar, setMostrar] = useState(false);
    const [modalIdPedido, setModalIdPedido] = useState([]);
    const [modalProdutoProdutor, setModalProdutoProdutor] = useState([]);
    const [modalIdRecibo, setModalIdRecibo] = useState([]);
    const [modalIdProduto, setModalIdProduto] = useState([]);
    const [modalCategoria, setModalCategoria] = useState([]);
    const [modalPreco, setModalPreco] = useState([]);
    const [modalDisponivel, setModalDisponivel] = useState([]);
    const [modalPedidoNovo, setModalPedidoNovo] = useState([]);
    const [modalPedidoAntigo, setModalPedidoAntigo] = useState([]);
    const [modalValorFinalNovo, setModalValorFinalNovo] = useState([]);


    //Trabalhar bases de dados
    const [produtosProdutores, setProdutosProdutores] = useState([]);
    const [semana, setSemana] = useState([]);
    const [valorFinal, setValorFinal] = useState([]);
    const [valorTaxa, setValorTaxa] = useState(0);
    const [valorEntrega, setValorEntrega] = useState(0);

    //Para filtros
    const [optionsProssumidores, setOptionsProssumidores] = useState([]);
    const [filtroProssumidores, setFiltroProssumidores] = useState([]);
    const [optionsProdutores, setOptionsProdutores] = useState([]);
    const [filtroProdutores, setFiltroProdutores] = useState([]);
    const [optionsProdutos, setOptionsProdutos] = useState([]);
    const [filtroProdutos, setFiltroProdutos] = useState([]);
    const [optionsCategorias, setOptionsCategorias] = useState([]);
    const [filtroCategorias, setFiltroCategorias] = useState([]);
    const [mostrarPesquisa, setMostrarPesquisa] = useState(false);

    //Criar novo pedido
    const [mostrarNovoPedido, setMostrarNovoPedido] = useState(false);
    const [todosProssumidores, setTodosProssumidores] = useState(false);
    const [criarPedidoProssumidores, setCriarPedidoProssumidores] = useState(false);
    const [diminuirEstoque, setDiminuirEstoque] = useState([]);
    const [criarPedidoComprado, setCriarPedidoComprado] = useState([]);
    const [compradoFiltradoParaNovoPedido, setCompradoFiltradoParaNovoPedido] = useState([]);

    //ReiniciarPágina
    const [restart, setRestart] = useState(false);

    useEffect(() => {

        //Obter prossumidores
        api.get('/usuarios')
            .then(results => {
                setTodosProssumidores(results.data
                    .filter(usuario => usuario?.prossumidor !== undefined)
                )
            })
            .catch(e => alert(e))
        //obter taxa
        api.get('taxas')
            .then(response => {
                const dados = response.data
                const novoValorEntrega = dados // inserir _id de entrega
                    .filter(dado => dado._id === '5edae6db3533600004c8e215')
                    .map(dado => dado.valor)
                const novoValorTaxa = dados // inserir _id de taxa
                    .filter(dado => dado._id === '5edae6f63533600004c8e216')
                    .map(dado => dado.valor)
                setValorEntrega(novoValorEntrega[0])
                setValorTaxa(novoValorTaxa[0])
            }).catch(e => alert(e));

        //Buscar pedidos para determinada data
        try {
            if(diaJSON?.[0]){
                api.get('pedidos/' + diaJSON)
                    .then(response => {
                        const dados = response.data.map(recibo => recibo.pedido)

                        /*console.log('Dados de soma de demanda', resumirEstoque)
                        console.log('Dados achatados de demanda', response.data.map(recibos => recibos.pedido).reduce((a,b) => a.concat(b)))
                        console.log('dia', dia)*/

                        if (JSON.stringify(response.data) !== JSON.stringify(criarPedidoComprado)) {
                            setCriarPedidoComprado(response.data)
                        }

                        if(dados?.[0] === undefined){

                            /*console.log('----------------')
                            console.log('#comprado.1 = skipped')
                            console.log('dados?.[0] is undefined')
                            console.log('comprado', comprado)
                            console.log('----------------')

                            console.log('comprado', comprado?.[0])
                            console.log('dadosTrabalhados', dadosTrabalhados?.[0])
                            console.log('dadosReserva', dadosReserva?.[0], dadosReserva)
                            console.log('optionsCategorias', optionsCategorias?.[0])
                            console.log('optionsProdutores', optionsProdutores?.[0])
                            console.log('optionsProssumidores', optionsProssumidores?.[0])
                            console.log('resumirEstoque', resumirEstoque?.[0])*/
                            if(comprado?.[0]){setComprado([])}
                            if(dadosTrabalhados?.[0]){setDadosTrabalhados([])}
                            if(dadosReserva?.[0]){setDadosReserva([])}
                            if(optionsCategorias?.[0]){setOptionsCategorias([])}
                            if(optionsProdutores?.[0]){setOptionsProdutores([])}
                            if(optionsProdutos?.[0]){setOptionsProdutos([])}
                            if(optionsProssumidores?.[0]){setOptionsProssumidores([])}
                            if(resumirEstoque?.[0]){setResumirEstoque([])}

                        } else {

                            //https://stackoverflow.com/questions/48639336/check-if-two-arrays-contain-identical-objects-react-componentdidupdate
                            if (JSON.stringify(dados) === JSON.stringify(comprado)) {

                                //diminuirEstoque para o <fazer pedidos>
                                const diminuirEstoque = dados.reduce((a,b) => a.concat(b))
                                .reduce((resultado, item) => {
                                    const idxFound = resultado
                                        .findIndex(produto => produto._idOferta === item.oferta._id)
                                    if (idxFound !== -1) {
                                        resultado[idxFound].demandaTotal += item.demanda;
                                    } else {
                                        resultado.push({ _idOferta: item.oferta._id, demandaTotal: item.demanda });
                                    }
                                    return resultado
                                }, []);

                                setDiminuirEstoque(diminuirEstoque);

                               /* console.log('----------------')
                                console.log('#comprado.2 = skipped')
                                console.log('JSON.stringify(dados[0]) == JSON.stringify(comprado)')
                                console.log('comprado', comprado)
                                console.log('dados[0]', dados[0])
                                console.log('----------------')*/
                                //console.log('dadosReserva at comprado.2.skipped, used for dadosTrabalhados', dadosReserva)

                                //Método para achatar os dados => https://stackoverflow.com/a/50862911/9295348
                                const criarDadosTrabalhados = []
                                response.data.forEach((e)=> {
                                        e.pedido.forEach((item) => {
                                            criarDadosTrabalhados.push({
                                                "_id": e._id,
                                                "prossumidor": e.prossumidor,
                                                "pedido": item
                                            })
                                        })
                                    })

                                const dadosFiltrados = criarDadosTrabalhados
                                    .filter(recibo => {
                                        if (filtroProssumidores?.[0]) {
                                            return filtroProssumidores
                                                .map(prossumidor => prossumidor._id)
                                                .includes(recibo.prossumidor._id)
                                        } else {
                                            return recibo
                                        }
                                    }
                                    ).filter(recibo => {
                                        if (filtroProdutores?.[0]) {
                                            return filtroProdutores
                                                .map(produtor => produtor._id)
                                                .includes(recibo.pedido.oferta.produto_produtor.produtor._id)
                                        } else {
                                            return recibo
                                        }

                                    }
                                    ).filter(recibo => {
                                        if (filtroProdutos?.[0]) {
                                            return filtroProdutos
                                                .map(produto => produto._id)
                                                .includes(recibo.pedido.oferta.produto_produtor.produto._id)
                                        } else {
                                            return recibo
                                        }

                                    }
                                    ).filter(recibo => {
                                        if (filtroCategorias?.[0]) {
                                            return filtroCategorias
                                                .map(categoria => categoria._id)
                                                .includes(recibo.pedido.oferta.produto_produtor.produto.categoria._id)
                                        } else {
                                            return recibo
                                        }
                                    }
                                    )

                                //console.log('dados filtrados at comprado.2.skipped, this is current DadosTrabalhados', dadosFiltrados)
                                setDadosTrabalhados(dadosFiltrados)

                            // flatten data => https://stackoverflow.com/questions/27266550/how-to-flatten-nested-array-in-javascript
                            // reduce and sum data => https://stackoverflow.com/questions/49020000/reduce-multiple-objects-into-one-adding-values-together
                            // reduce and sum actually used => https://gist.github.com/Falnesio/72c4bd5935bfc670080ea69e1b6da512
                                const resumirEstoqueAgora = dadosFiltrados
                                    .reduce((resultado, item) => {
                                        const idxFound = resultado
                                            .findIndex(produto => produto._idOferta === item.pedido.oferta._id)
                                        if (idxFound !== -1) {
                                            resultado[idxFound].demandaTotal += item.pedido.demanda;
                                        } else {
                                            resultado.push({
                                                _idOferta: item.pedido.oferta._id,
                                                demandaTotal: item.pedido.demanda,
                                                preco: item.pedido.oferta.preco
                                            });
                                        }
                                        return resultado
                                    }, []);

                                //console.log('resumirEstoque', resumirEstoqueAgora)
                                setResumirEstoque(resumirEstoqueAgora)

                            } else {
                                /*console.log('----------------')
                                console.log('#comprado.3 = new data')
                                console.log('JSON.stringify(dados[0]) != JSON.stringify(comprado)')
                                console.log('comprado', comprado)
                                console.log('dados[0]', dados[0])
                                console.log('----------------')*/
                                setComprado(dados);

                            //Método para achatar os dados => https://stackoverflow.com/a/50862911/9295348
                                const criarDadosTrabalhados = []
                                response.data.forEach((e)=> {
                                        e.pedido.forEach((item) => {
                                            criarDadosTrabalhados.push({
                                                "_id": e._id,
                                                "prossumidor": e.prossumidor,
                                                "pedido": item
                                            })
                                        })
                                    })

                                //Isso gera pare únicos => https://stackoverflow.com/a/33225063/9295348
                                const trabalharProssumidores = criarDadosTrabalhados
                                    .map(recibo => {return {_id: recibo.prossumidor._id, label: recibo.prossumidor.prossumidor}})
                                const pairesProssumidores = {};
                                const outputProssumidores = trabalharProssumidores
                                    .filter(function(item) {
                                        if (pairesProssumidores[item._id] === item.label ||
                                            pairesProssumidores[item.label] === item._id)
                                            return false;
                                        pairesProssumidores[item._id] = item.label;
                                        return true;
                                    });

                                //Isso gera pare únicos => https://stackoverflow.com/a/33225063/9295348
                                const trabalharProdutores = criarDadosTrabalhados
                                    .map(recibo => {return {
                                        _id: recibo.pedido.oferta.produto_produtor.produtor._id,
                                        label: recibo.pedido.oferta.produto_produtor.produtor.produtor
                                    }})
                                const pairesProdutores = {};
                                const outputProdutores = trabalharProdutores
                                    .filter(function(item) {
                                        if (pairesProdutores[item._id] === item.label ||
                                            pairesProdutores[item.label] === item._id)
                                            return false;
                                        pairesProdutores[item._id] = item.label;
                                        return true;
                                    });

                                //Isso gera pare únicos => https://stackoverflow.com/a/33225063/9295348
                                const trabalharProdutos = criarDadosTrabalhados
                                    .map(recibo => {return {
                                        _id: recibo.pedido.oferta.produto_produtor.produto._id,
                                        label: recibo.pedido.oferta.produto_produtor.produto.produto
                                    }})
                                const pairesProdutos = {};
                                const outputProdutos = trabalharProdutos
                                    .filter(function(item) {
                                        if (pairesProdutos[item._id] === item.label ||
                                            pairesProdutos[item.label] === item._id)
                                            return false;
                                        pairesProdutos[item._id] = item.label;
                                        return true;
                                    });

                                //Isso gera pare únicos => https://stackoverflow.com/a/33225063/9295348
                                const trabalharCategorias = criarDadosTrabalhados
                                    .map(recibo => {return {
                                        _id: recibo.pedido.oferta.produto_produtor.produto.categoria._id,
                                        label: recibo.pedido.oferta.produto_produtor.produto.categoria.categoria
                                    }})
                                const pairesCategorias = {};
                                const outputCategorias = trabalharCategorias
                                    .filter(function(item) {
                                        if (pairesCategorias[item._id] === item.label ||
                                            pairesCategorias[item.label] === item._id)
                                            return false;
                                        pairesCategorias[item._id] = item.label;
                                        return true;
                                    });


                                // Isso gera valores únicos => https://stackoverflow.com/a/38206980/9295348
                                // const uniqueProssumidores = [...new Set(trabalharProssumidores)]

                                //console.log('this goes to dadosReserva', criarDadosTrabalhados)
                                //console.log('sample prossumidores output: ', outputProssumidores)

                                //Enviar dados para Hooks
                                    //this will bet set later:
                                    // setDadosTrabalhados(criarDadosTrabalhados)
                                //setDadosReserva(criarDadosTrabalhados)
                                    //Filtros
                                setOptionsProssumidores(outputProssumidores)
                                //setFiltroProssumidores(outputProssumidores)
                                setOptionsProdutores(outputProdutores)
                                //setFiltroProdutores(outputProdutores)
                                setOptionsProdutos(outputProdutos)
                                //setFiltroProdutos(outputProdutos)
                                setOptionsCategorias(outputCategorias)
                                //setFiltroCategorias(outputCategorias)

                            // flatten data => https://stackoverflow.com/questions/27266550/how-to-flatten-nested-array-in-javascript
                            // reduce and sum data => https://stackoverflow.com/questions/49020000/reduce-multiple-objects-into-one-adding-values-together
                            // reduce and sum actually used => https://gist.github.com/Falnesio/72c4bd5935bfc670080ea69e1b6da512
                                /*const resumirEstoque = dados.reduce((a,b) => a.concat(b))
                                    .reduce((resultado, item) => {
                                        const idxFound = resultado
                                            .findIndex(produto => produto._idOferta === item.oferta._id)
                                        if (idxFound !== -1) {
                                            resultado[idxFound].demandaTotal += item.demanda;
                                        } else {
                                            resultado.push({ _idOferta: item.oferta._id, demandaTotal: item.demanda });
                                        }
                                        return resultado
                                    }, []);

                                setResumirEstoque(resumirEstoque);*/
                            }
                        }
                        //console.log('Part.3: Complete')
                    })
                    .catch(e => alert(e));
            }
        } catch (e) {
            alert(e);
        }

        //Reiniciar entradas
       /* setNovoProdutoProdutor({label: 'Escolher Produto'});
        setNovaQuantidade([]);
        setNovoPreco([]);*/
    }, [startDate, diaJSON, mostrarTabela, mostrarResumo, restart, comprado, dia, filtroProssumidores, filtroCategorias, filtroProdutores, filtroProdutos, dadosReserva]);

//https://www.tutorialspoint.com/javascript/date_getday.htm
    const eQuarta = date => {
        const day = date.getDay();
        return day === 3;
    };

    //Formatar Data
    async function handleDate(diaSelecionado) {
        await setComprado([])
        await setDadosTrabalhados([])
        await setDadosReserva([])
        await setOptionsCategorias([])
        await setOptionsProdutores([])
        await setOptionsProdutos([])
        await setOptionsProssumidores([])
        await setResumirEstoque([])
        try {
            const diaTrabalhado = await Intl.DateTimeFormat("pt-Br", {
                year: "numeric",
                month: "long",
                day: "2-digit"
            }).format(diaSelecionado)
            await setDia(diaTrabalhado)
            await setDiaJSON(JSON.parse(JSON.stringify(diaSelecionado)))
            await setStartDate(diaSelecionado)
        } catch (e) {
            alert("Start date não escolhida" + e);
        }
    }

        async function handleDeletar(idproduto, idpedido){
        try {
            await api.patch('pedidos/deletarum/' + idpedido,
                    {
                            '_id': idproduto
                        }
                    )

             setRestart(!restart)
        }catch (e) {
            alert(e)
        }

    }

    async function handleAlterar(){
        try {
            //await handleRefresh()
            await api.put('pedidos/alterarum/' + modalIdRecibo,
                        {
                                '_id': modalIdPedido,
                                'demanda': modalPedidoNovo
                            }
                        )
            setRestart(!restart)
        } catch (e) {
            alert(e)
        }

        setMostrar(false)
        setModalProdutoProdutor(false)
        setModalIdRecibo(false)
        setModalIdProduto(false)
        setModalIdPedido(false)
        setModalCategoria(false)
        setModalPreco(false)
        setModalDisponivel(false)
        setModalPedidoNovo(false)
        setModalPedidoAntigo(false)
        setModalValorFinalNovo(false)
    }

    async function handleRefresh () {
        setRestart(!restart)
    }

//date formatting: https://www.carlrippon.com/formatting-dates-and-numbers-in-react/
    return(
        <Fragment>
            <h1 className="SemanaTitulotopo"> Administrar os Pedidos </h1>
            <h1 className="SemanaTitulofundo">{startDate == null ? '': ` do dia ${dia}` }</h1>
            <DatePicker
                dateFormat="dd/MM/yyyy"
                locale="pt-BR"
                selected={startDate}
                filterDate={eQuarta}
                onChange={date => {
                    handleDate(date)
                }}
                placeholderText="Escolha uma quarta-feira"
            />
            {startDate == null? '' :
                <Paper>
                    <Tabs
                        centered
                    >
                        <Tab
                            label='Tabela da Semana'
                            onClick={() => {
                                setMostrarResumo(false)
                                setMostrarTabela(true)
                            }}
                        />
                        <Tab
                          label='Resumo dos Prossumidores'
                          onClick={() => {
                              setMostrarResumo(true)
                              setMostrarTabela(false)
                          }}
                        />
                    </Tabs>
              </Paper>}
            {(mostrarResumo && startDate != null)? <ResumoProssumidores recibos={criarPedidoComprado} entrega={valorEntrega} refresh={handleRefresh}/> : ''}
            {(mostrarTabela && startDate != null)?
                <div className='mostrarTabela-container'>
                    {mostrarPesquisa ?
                        <div className='filtros-container-adm'>
                            <Tabs>
                                <Tab
                                  label='Filtros'
                                  onClick={() => {
                                      setMostrarPesquisa(false)
                                  }}
                                />
                                <Tab className='adm-criar-pedido'
                                     label='Criar Pedido Novo'
                                     onClick={() =>{
                                         setMostrarNovoPedido(true)
                                     }}
                                />
                            </Tabs>
                            <Table className='adm-filtros-table'>
                                <Table.Row className='adm-filtros-table-row'>
                                    <Table.HeaderCell className='adm-filtro'>
                                        Prossumidores:
                                        <Select
                                            isMulti
                                            options={optionsProssumidores}
                                            getOptionValue={option => option._id}
                                            value={filtroProssumidores}
                                            onChange={val => {
                                                setFiltroProssumidores(val)
                                            }}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='adm-filtro'>
                                        Produtores:
                                        <Select
                                            isMulti
                                            options={optionsProdutores}
                                            getOptionValue={option => option._id}
                                            value={filtroProdutores}
                                            onChange={val => {
                                                setFiltroProdutores(val)
                                            }}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='adm-filtro'>
                                        Categorias:
                                        <Select
                                            isMulti
                                            options={optionsCategorias}
                                            getOptionValue={option => option._id}
                                            value={filtroCategorias}
                                            onChange={val => {
                                                setFiltroCategorias(val)
                                            }}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className='adm-filtro'>
                                        Produtos:
                                        <Select
                                            isMulti
                                            options={optionsProdutos}
                                            getOptionValue={option => option._id}
                                            value={filtroProdutos}
                                            onChange={val => {
                                                setFiltroProdutos(val)
                                            }}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table>
                        </div>
                    :
                        <Tabs>
                            <Tab
                              label='Filtros'
                              onClick={() => {
                                  setMostrarPesquisa(true)
                              }}
                            />
                            <Tab className='adm-criar-pedido'
                                 label='Criar Pedido Novo'
                                 onClick={() =>{
                                     setMostrarNovoPedido(true)
                                     //console.log("todosProssumidores: ",todosProssumidores)
                                 }}
                            />
                        </Tabs>
                    }
                    <Table >
                        <Table.Header className='adm-tabela-agregados'>
                            <Table.Row className='adm-tabela-agregados'>
                                <Table.HeaderCell className='adm-tabela-agregados'>
                                    Produtos presentes: R$ {
                                    Number(
                                        resumirEstoque
                                            .reduce((resultado, item) =>
                                                resultado + (item.preco * item.demandaTotal)
                                            , 0)).toFixed(2)
                                    }
                                </Table.HeaderCell>
                                <Table.HeaderCell className='adm-tabela-agregados'>
                                    {/*Taxa de {valorTaxa}%: R$ {
                                    Number(
                                        resumirEstoque
                                            .reduce((resultado, item) =>
                                                resultado + (item.preco * item.demandaTotal * (valorTaxa/100))
                                            , 0)).toFixed(2)
                                    }*/}
                                </Table.HeaderCell>
                                <Table.HeaderCell className='adm-tabela-agregados'>
                                    {/*Taxa + Produtos: R$ {
                                    Number(
                                        resumirEstoque
                                            .reduce((resultado, item) =>
                                                resultado + (item.preco * item.demandaTotal * (valorTaxa/100))
                                            , 0)
                                    +
                                    resumirEstoque
                                            .reduce((resultado, item) =>
                                                resultado + (item.preco * item.demandaTotal)
                                            , 0)).toFixed(2)
                                    }*/}
                                </Table.HeaderCell>
                                <Table.HeaderCell className='adm-tabela-agregados'>

                                </Table.HeaderCell>
                                <Table.HeaderCell className='adm-tabela-agregados'>

                                </Table.HeaderCell>
                                <Table.HeaderCell className='adm-tabela-agregados'>

                                </Table.HeaderCell>
                                <Table.HeaderCell className='adm-tabela-agregados'>

                                </Table.HeaderCell>
                                <Table.HeaderCell className='adm-tabela-agregados'>

                                </Table.HeaderCell>
                                <Table.HeaderCell className='adm-tabela-agregados'>

                                </Table.HeaderCell>
                                <Table.HeaderCell className='adm-tabela-agregados'>

                                </Table.HeaderCell>
                                {/*<Table.HeaderCell className='adm-tabela-agregados'>

                                </Table.HeaderCell>*/}
                            </Table.Row>
                        </Table.Header>
                    </Table>
                    <Table className='adm-tabela'>
                        <Table.Header className='adm-tabela-header'>
                            <Table.Row className='adm-tabela-header'>
                                {/*<Table.HeaderCell>
                                    Id
                                </Table.HeaderCell>*/}
                                <Table.HeaderCell>
                                    Prossumidor:
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Produto:
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Produtor:
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Categoria:
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Preço
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Unidade
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Pedido
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Preço Final
                                </Table.HeaderCell>
                                <Table.HeaderCell>

                                </Table.HeaderCell>
                                <Table.HeaderCell>

                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {dadosTrabalhados.map(recibo => (
                                <Table.Row className="meus-pedidos-row" key={recibo.pedido._id}>
                                    {/*<Table.Cell>
                                        <p>
                                            Recibo: {recibo._id}<br/>
                                            Pedido: {recibo.pedido._id}
                                        </p>
                                    </Table.Cell>*/}
                                    <Table.Cell>
                                        {recibo.prossumidor.prossumidor}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Popup position='right center' content={
                                            <Table.HeaderCell  className="semana-pop">
                                                {recibo.pedido.oferta.produto_produtor.produto.descricao}
                                            </Table.HeaderCell>
                                        } trigger={<p>{recibo.pedido.oferta.produto_produtor.produto.produto}</p>} />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Popup position='right center' content={
                                            <Table.HeaderCell  className="semana-pop">
                                                Se quiser podemos fazer com que o produtor tenha uma descrição
                                            </Table.HeaderCell>
                                        } trigger={<p>{recibo.pedido.oferta.produto_produtor.produtor.produtor}</p>} />
                                    </Table.Cell>
                                    <Table.Cell className="nowrap">
                                        {recibo.pedido.oferta.produto_produtor.produto.categoria.categoria}
                                    </Table.Cell>
                                    <Table.Cell className="nowrap">
                                        R$ {recibo.pedido.oferta.preco}
                                    </Table.Cell>
                                    <Table.Cell className="nowrap">
                                        {recibo.pedido.oferta.produto_produtor.produto.quantia} {recibo.pedido.oferta.produto_produtor.produto.unidade.unidade}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {recibo.pedido.demanda}
                                    </Table.Cell>
                                    <Table.Cell className="nowrap">
                                        R$ {recibo.pedido.demanda * recibo.pedido.oferta.preco}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <button onClick={() => {
                                            handleDeletar(recibo.pedido._id, recibo._id)
                                        }}>
                                            Cancelar Pedido
                                        </button>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <button onClick={() => {
                                            setMostrar(true)
                                            setModalProdutoProdutor(<span>{recibo.pedido.oferta.produto_produtor.produto.produto} de {recibo.pedido.oferta.produto_produtor.produtor.produtor}</span>)
                                            setModalIdRecibo(recibo._id)
                                            setModalIdPedido(recibo.pedido._id)
                                            setModalIdProduto(recibo.pedido.oferta._id)
                                            setModalCategoria(recibo.pedido.oferta.produto_produtor.produto.categoria.categoria)
                                            setModalPreco(recibo.pedido.oferta.preco)
                                            setModalDisponivel((recibo.pedido.oferta.oferta - resumirEstoque.filter(produto => produto._idOferta === recibo.pedido.oferta._id).map(produto => produto.demandaTotal)))
                                            setModalPedidoNovo(recibo.pedido.demanda)
                                            setModalPedidoAntigo(recibo.pedido.demanda)
                                            setModalValorFinalNovo((recibo.pedido.oferta.preco * recibo.pedido.demanda))
                                        }}>
                                            Corrigir
                                        </button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                            {mostrar && <div className="modaloverlay"/>}
                            <Modal className="modal"
                              open={mostrar}
                              closeOnEscape={true}
                              closeOnDimmerClick={false}
                              //onClose={}
                            >
                              <Card>
                                  <Card.Content>
                                    <Card.Header>{modalProdutoProdutor}</Card.Header>
                                    <Card.Meta>ID-Recibo: {modalIdRecibo}</Card.Meta>
                                    <Card.Meta>ID-Pedido: {modalIdPedido}</Card.Meta>
                                    <Card.Meta>ID-Produto: {modalIdProduto}</Card.Meta>
                                    <Card.Meta>Categoria: {modalCategoria}</Card.Meta>
                                    <Card.Description>
                                        Preço: R$ {modalPreco}
                                    </Card.Description>
                                    <Card.Description>
                                        Disponível: {modalDisponivel - (modalPedidoNovo - modalPedidoAntigo)} considerando sua reserva de {modalPedidoNovo}
                                    </Card.Description>
                                    <Card.Description>
                                        Valor do Pedido: R$ {modalValorFinalNovo}
                                    </Card.Description>
                                  </Card.Content>
                                  <Card.Content extra>
                                      <input
                                          type="number"
                                          placeholder={modalPedidoAntigo}
                                          max={modalDisponivel + modalPedidoAntigo}
                                          min={1}
                                          value={modalPedidoNovo}
                                          onChange={e => {
                                              if (parseFloat(e.target.value) !== modalPedidoNovo) {
                                                  if (parseFloat(e.target.value) <= (modalDisponivel + modalPedidoAntigo) && parseFloat(e.target.value) > 0) {
                                                      setModalPedidoNovo(e.target.value)
                                                      setModalValorFinalNovo(e.target.value * modalPreco)
                                                  } else {
                                                      return setModalPedidoNovo(1)
                                                  }
                                              }
                                          }}
                                      />
                                    <div className='ui two buttons'>
                                      <button color='green' onClick={() => {
                                          setMostrar(false)
                                          handleAlterar()
                                      }}>
                                          Alterar
                                          Pedido
                                      </button>
                                      <button color='red' onClick={() => {
                                          setMostrar(false)
                                          setModalProdutoProdutor(false)
                                          setModalIdRecibo(false)
                                          setModalIdProduto(false)
                                          setModalIdPedido(false)
                                          setModalCategoria(false)
                                          setModalPreco(false)
                                          setModalDisponivel(false)
                                          setModalPedidoNovo(false)
                                          setModalPedidoAntigo(false)
                                          setModalValorFinalNovo(false)
                                      }}>
                                          Não alterar
                                      </button>
                                    </div>
                                  </Card.Content>
                              </Card>
                            </Modal>

                            {/*Fazer novo pedido*/}
                            {mostrarNovoPedido && <div className="modaloverlay"/>}
                            <Modal className="modal modal-de-criar-pedido"
                              open={mostrarNovoPedido}
                              closeOnEscape={true}
                              closeOnDimmerClick={false}
                              //onClose={}
                            >
                              <div>
                                  <Card.Content extra>
                                    <div className='ui two buttons'>
                                      <button onClick={() => {
                                          setMostrarNovoPedido(false)
                                      }}>
                                          Voltar
                                      </button>
                                    </div>
                                  </Card.Content>
                                  <Card.Content>
                                      Prossumidores:
                                        <Select
                                            isMulti
                                            options={todosProssumidores.map(dado => ({
                                                prossumidor: dado.prossumidor,
                                                label: dado.usuario,
                                                auth0: dado.auth0,
                                                endereco: dado?.endereco
                                            }))}
                                            value={criarPedidoProssumidores}
                                            onChange={val => {
                                                setCriarPedidoProssumidores(val)
                                                if(val){
                                                    setCompradoFiltradoParaNovoPedido(criarPedidoComprado
                                                        .filter(resultado => resultado.prossumidor._id === val[0]?.prossumidor)
                                                        .map(dados => dados.pedido)[0]
                                                    )
                                                }
                                                setRestart(!restart)
                                            }}
                                        />
                                  </Card.Content>
                                  {criarPedidoProssumidores?
                                      <div className='fazer-pedidos-table-container'>
                                          <FazerPedidos
                                              dia={diaJSON}
                                              usuario={criarPedidoProssumidores[0]}
                                              comprado={compradoFiltradoParaNovoPedido}
                                              diminuirEstoque={diminuirEstoque}
                                              valorTaxa={valorTaxa}
                                              valorEntrega={valorEntrega}
                                              refresh={handleRefresh}
                                          />
                                      </div>
                                  :
                                      ''
                                  }
                              </div>
                            </Modal>
                        </Table.Body>
                    </Table>
                </div>
            :
                ''
            }
        </Fragment>
    );
}