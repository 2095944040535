import React from "react";

import './styles.css';

import nagoCasa from "../../assets/NAGO-logo-casa.png";
import logoImg35 from "../../assets/NAGO-logo-16.png";
import ufjf from "../../assets/logo_ufjf_campus_gv.png";
import cat from "../../assets/cat-38.png";
import NavBar from "../../components/NavBar";
import Layout from "../../components/layout";

export default function Blog() {
    return(
        <Layout>
            <div id="outer-container">

                <NavBar noOverlay pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" }/>

                <main id="page-wrap">
                    <div className="logon-container">
                        <section className="form">
                            <img src={cat} alt="CAT" width={100}/>
                            <img src={logoImg35} alt="Nagô" width={100}/>
                            <img src={ufjf} alt="UFJF" width={200}/>

                            <form>
                                <h1>Agradecemos pela visita</h1>
                            </form>
                        </section>

                        <img src={nagoCasa} alt="Casa Nagô" width={500}/>
                    </div>
                </main>
            </div>
        </Layout>
    );
}