import React, { useState, useEffect } from "react";
import { useAuth0 } from "../react-auth0-spa";
import { Link } from "react-router-dom";
import { bubble as Menu } from 'react-burger-menu';
import { FaHome, FaHandshake, FaAddressCard, FaStore, FaRegEye} from "react-icons/fa";
import { GiHand, RiContactsLine, IoIosBasket, GoGraph, GiSellCard, GiFloorHatch, GiBookmark } from "react-icons/all";

import "./styles.css"

import api from '../services/api'

const NavBar = () => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const [prossumidorAtivado, setProssumidorAtivado] = useState(false);
  const [produtorConfirmado, setProdutorConfirmado] = useState(false);
  const [prossumidorConfirmado, setProssumidorConfirmado] = useState(false);
  const [usuario, setUsuario] = useState(false);

  useEffect(() => {
    api.get('/prossumidores/' + user?.sub)
      .then(response => {
        let dados = response.data
        setProssumidorAtivado(dados?.ativo)
        setProssumidorConfirmado(dados?._id)
      }).catch(err => alert(err))
    api.get('/produtores/' + user?.sub)
      .then(response => {
        let dados = response.data
        setProdutorConfirmado(dados?._id)
      }).catch(err => alert(err))
    api.get('usuarios/' + user?.sub)
        .then(response => {
                if((JSON.stringify(response.data) !== JSON.stringify(usuario))){
                    setUsuario(response.data)
                }
        }).catch(err => alert(err))
  }, [])

  return (
    <Menu>
      {!isAuthenticated && (
        <Link className="menu-item" onClick={() => loginWithRedirect({})}><pre className="tab"><FaAddressCard/>     Entrar / Cadastro</pre></Link>
      )}

      {isAuthenticated && <Link className="menu-item" onClick={() => logout()}><pre className="tab"><GiHand/>     Sair</pre></Link>}


      {isAuthenticated &&
          <a id="inicio" className="menu-item" href="https://www.redetamandua.com">
            <pre className="tab"><FaHome/>     Início</pre>
          </a>
      }
      {!isAuthenticated &&
          <a id="inicio" className="menu-item" href="https://www.redetamandua.com">
            <pre className="tab"><FaHome/>     Início</pre>
          </a>
      }
      {!isAuthenticated &&
          <a id="blog" className="menu-item" href="https://www.redetamandua.com/blog">
            <pre className="tab"><GiFloorHatch/>     Blog</pre>
          </a>
      }
      {isAuthenticated &&
          <a id="blog" className="menu-item" href="https://www.redetamandua.com/blog">
            <pre className="tab"><GiFloorHatch/>     Blog</pre>
          </a>
      }
      {isAuthenticated &&
          <Link id="home" className="menu-item" to="/">
            <pre className="tab"><FaStore/>     Loja</pre>
          </Link>
      }
      {isAuthenticated &&
          <Link id="principal" className="menu-item" to="/principal">
            <pre className="tab"><RiContactsLine/>     Perfil</pre>
          </Link>
      }
      {isAuthenticated && (usuario?.classe === "5ec720e73c9eef00047d9a42" || usuario?.classe === "5ec720fb3c9eef00047d9a43") &&
          <Link id="administrar" className="menu-item" to="/administrar">
            <pre className="tab"><GiBookmark/>     Administração</pre>
          </Link>
      }
      {!isAuthenticated &&
          <Link id="home" className="menu-item" to="/">
            <pre className="tab"><FaStore/>     Loja</pre>
          </Link>
      }
      {(isAuthenticated && prossumidorAtivado) &&
          <Link id="pedido" className="menu-item" to="/pedido">
            <pre className="tab"><FaHandshake/>     Pedido</pre>
          </Link>
      }
      {(isAuthenticated && !prossumidorAtivado && prossumidorConfirmado) &&
          <Link id="pedido" className="menu-item" to="/">
            <pre className="prossumidor-inativo"><FaHandshake/>     Prossumidor Inativo</pre>
          </Link>
      }
      {(isAuthenticated && prossumidorConfirmado) &&
          <Link id="historicoprossumidor" className="menu-item" to="/historicoprossumidor">
            <pre className="tab"><IoIosBasket/>     Histórico do Prossumidor</pre>
          </Link>
      }
      {(isAuthenticated && produtorConfirmado) &&
          <Link id="historicoprodutor" className="menu-item" to="/historicoprodutor">
            <pre className="tab"><GiSellCard/>     Histórico do Produtor</pre>
          </Link>
      }
      {(isAuthenticated && produtorConfirmado) &&
          <Link id="analise" className="menu-item" to="/analise">
            <pre className="tab"><GoGraph/>     Análise</pre>
          </Link>
      }
      {isAuthenticated &&
            <pre> </pre>
      }
      {!isAuthenticated &&
            <pre> </pre>
      }
      {isAuthenticated &&
          <Link id="privacypolicy" className="menu-item" to="/privacypolicy">
            <pre className="tab"><FaRegEye/>     Política de Privacidade</pre>
          </Link>
      }
      {!isAuthenticated &&
          <Link id="privacypolicy" className="menu-item" to="/privacypolicy">
            <pre className="tab"><FaRegEye/>     Política de Privacidade</pre>
          </Link>
      }
    </Menu>
  );
};

export default NavBar;