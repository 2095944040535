import React, { Fragment } from "react";


export default function ConditionalPedidos (props) {

    //console.log('props',props);

    return (
        <Fragment>
            {((props.usuario === null) || (props.usuario === undefined || props.usuario.length === 0)) ?
                <div>
                    <pre> </pre>
                    <h1> Não é possível fazer pedidos sem confirmar </h1>
                    <h1> que gostaria de compartilhar seus dados com a rede </h1>
                    <h1> na página de perfil </h1>
                    <pre> </pre>
                </div>
                :
                <div>
                    {props.usuario?.prossumidor == null ?
                        <div>
                            <pre> </pre>
                            <h1> Não é possível fazer pedidos sem confirmar </h1>
                            <h1> que é um prossumidor </h1>
                            <h1> na página de perfil </h1>
                            <pre> </pre>
                        </div>
                        :
                        <div>
                            {props.children}
                        </div>
                    }
                </div>
            }
        </Fragment>
    );
}