import React, { Fragment, useState, useEffect } from "react";
import {Input, Popup, Table} from "semantic-ui-react";
import api from './../../services/api';
import Select from "react-select";

export default function FazerPedidos(props) {
    //Base de dados
    const [ofertas, setOfertas] = useState([]);
    //Alterar tabela
    const [demanda, setDemanda] = useState(0);
    const [filtrarOfertas, setFiltrarOfertas] = useState([]);
    //Desabilitar botão
    const [desabilitar, setDesabilitar] = useState([]);

    //Para filtros
    const [optionsProdutores, setOptionsProdutores] = useState([]);
    const [filtroProdutores, setFiltroProdutores] = useState([]);
    const [optionsProdutos, setOptionsProdutos] = useState([]);
    const [filtroProdutos, setFiltroProdutos] = useState([]);
    const [optionsCategorias, setOptionsCategorias] = useState([]);
    const [filtroCategorias, setFiltroCategorias] = useState([]);
    const [mostrarPesquisa, setMostrarPesquisa] = useState(false);


    useEffect(() => {

        /*console.log('Recomeçando... ');
        console.log('props: ', props);
        console.log('props.usuario: ', props.usuario);
        console.log('props.comprado: ', props.comprado);*/

        try {
            if(!(props?.dia == null)){
                api.get('ofertas/' + props.dia)
                    .then(response => {
                        //setOfertas(response.data);

                        //Isso gera pares únicos => https://stackoverflow.com/a/33225063/9295348
                        const trabalharProdutos = response.data
                            .map(oferta => {return {_id: oferta.produto_produtor.produto._id, label: oferta.produto_produtor.produto.produto}})
                        const pairesProdutos = {};
                        const outputProdutos = trabalharProdutos
                            .filter(function(item) {
                                if (pairesProdutos[item._id] === item.label ||
                                    pairesProdutos[item.label] === item._id)
                                    return false;
                                pairesProdutos[item._id] = item.label;
                                return true;
                            });
                        setOptionsProdutos(outputProdutos)

                        //Isso gera pares únicos => https://stackoverflow.com/a/33225063/9295348
                        const trabalharCategorias = response.data
                            .map(oferta => {return {_id: oferta.produto_produtor.produto.categoria._id, label: oferta.produto_produtor.produto.categoria.categoria}})
                        const pairesCategorias = {};
                        const outputCategorias = trabalharCategorias
                            .filter(function(item) {
                                if (pairesCategorias[item._id] === item.label ||
                                    pairesCategorias[item.label] === item._id)
                                    return false;
                                pairesCategorias[item._id] = item.label;
                                return true;
                            });
                        setOptionsCategorias(outputCategorias)

                        //Isso gera pares únicos => https://stackoverflow.com/a/33225063/9295348
                        const trabalharProdutores = response.data
                            .map(oferta => {return {_id: oferta.produto_produtor.produtor._id, label: oferta.produto_produtor.produtor.produtor}})
                        const pairesProdutores = {};
                        const outputProdutores = trabalharProdutores
                            .filter(function(item) {
                                if (pairesProdutores[item._id] === item.label ||
                                    pairesProdutores[item.label] === item._id)
                                    return false;
                                pairesProdutores[item._id] = item.label;
                                return true;
                            });
                        setOptionsProdutores(outputProdutores)

                        //Método para achatar os dados => https://stackoverflow.com/a/50862911/9295348
                        const dadosFiltrados = response.data
                            .filter(oferta => {
                                if (filtroProdutores?.[0]) {
                                    return filtroProdutores
                                        .map(produtor => produtor._id)
                                        .includes(oferta.produto_produtor.produtor._id)
                                } else {
                                    return oferta
                                }
                            }
                            ).filter(oferta => {
                                if (filtroProdutos?.[0]) {
                                    return filtroProdutos
                                        .map(produto => produto._id)
                                        .includes(oferta.produto_produtor.produto._id)
                                } else {
                                    return oferta
                                }
                            }
                            ).filter(oferta => {
                                if (filtroCategorias?.[0]) {
                                    return filtroCategorias
                                        .map(categoria => categoria._id)
                                        .includes(oferta.produto_produtor.produto.categoria._id)
                                } else {
                                    return oferta
                                }
                            }
                        )

                        setOfertas(dadosFiltrados)
                });
            }
        } catch (e) {
            alert(e);
        }

        try {
            //console.log('Setting comprados');
            if (!(props?.comprado == null)) {
                /*console.log('Iniciar filtro');
                    console.log('props.comprado', props.comprado);*/
                    let filtro = props.comprado
                        .map(pedido =>
                            pedido.oferta._id
                        );
                    setFiltrarOfertas(filtro);
                    //console.log("FILTRO", filtro);
            }
        } catch (e) {
            alert(e);
        }

    }, [props, desabilitar, demanda, filtroProdutos, filtroCategorias, filtroProdutores]);



    async function handleComprar(quantopedido, oferta) {

        await setDesabilitar(prev => ({ ...prev, [oferta._id]: true }));

        try {

            if (quantopedido === undefined
                || isNaN(+quantopedido)
                || quantopedido <= 0
            ) {
                throw new Error("Nenhum número inserido. Utilize pontos e não vírgulas.")
            }

            if (props.diminuirEstoque.map(produto => produto._idOferta).includes(oferta._id)) {

                const maximo = ( oferta.oferta - props.diminuirEstoque.filter(produto => produto._idOferta === oferta._id).map(produto => produto.demandaTotal))

                if (quantopedido > maximo
                ) {
                    alert("Não é possível comprar mais do que o disponível")
                    await setDesabilitar(prev => ({ ...prev, [oferta._id]: false }));

                } else {
                    await api.put('pedidos/' + props.dia,
                    {
                            'prossumidor': props.usuario.prossumidor,
                            'data': props.dia,
                            'entrega': props.entrega,
                            'taxa': props.valorTaxa,
                            'oferta': oferta._id,
                            'demanda': quantopedido,
                           // 'max': oferta.oferta
                        }
                    )
                }

            } else {

                if (quantopedido > oferta.oferta
                ) {
                    alert("Não é possível comprar mais do que o disponível")
                    await setDesabilitar(prev => ({ ...prev, [oferta._id]: false }));

                } else {
                    await api.put('pedidos/' + props.dia,
                    {
                            'prossumidor': props.usuario.prossumidor,
                            'data': props.dia,
                            'entrega': props.entrega,
                            'taxa': props.valorTaxa,
                            'oferta': oferta._id,
                            'demanda': quantopedido
                        }
                    )
                }
            }

            await props.refresh()
        } catch (e) {
            alert(e);
            await setDesabilitar(prev => ({ ...prev, [oferta._id]: false }));
        }

    }

//https://stackoverflow.com/questions/61649738
    async function handleEntrada(e, id) {
        try {
            if(e === 0 || e.target.value === undefined) {
                const val = 0
                setDemanda(prev => ({ ...prev, [id]: val }));
            } else {
                const val = e.target.value;
                setDemanda(prev => ({ ...prev, [id]: val }));
            }

            props.refresh()
        } catch (e) {
            alert(e);
        }
    }

    /*async function handleDisable(oferta) {
        console.log('desabilitar',desabilitar[oferta._id])
        try {
            await setDesabilitar(prev => ({ ...prev, [oferta._id]: true }));
        }catch (e) {
            alert(e)
            await setDesabilitar(prev => ({ ...prev, [oferta._id]: false }));
        }
    }*/


    return(
        <Fragment>
            <h1>Ofertas</h1>
            <Table className='pedido-filtros-table'>
                <Table.Row className='pedido-filtros-table-row'>
                    <Table.HeaderCell className='pedido-filtro'>
                        Produtores:
                        <Select
                            isMulti
                            options={optionsProdutores}
                            getOptionValue={option => option._id}
                            value={filtroProdutores}
                            onChange={val => {
                                setFiltroProdutores(val)
                            }}
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell className='pedido-filtro'>
                        Categorias:
                        <Select
                            isMulti
                            options={optionsCategorias}
                            getOptionValue={option => option._id}
                            value={filtroCategorias}
                            onChange={val => {
                                setFiltroCategorias(val)
                            }}
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell className='pedido-filtro'>
                        Produtos:
                        <Select
                            isMulti
                            options={optionsProdutos}
                            getOptionValue={option => option._id}
                            value={filtroProdutos}
                            onChange={val => {
                                setFiltroProdutos(val)
                            }}
                        />
                    </Table.HeaderCell>
                </Table.Row>
            </Table>
            <Table>
                <Table.Header>
                    <Table.Row className='fazer-pedidos-header-row'>
                        {/*<Table.HeaderCell>
                            ID Produto
                        </Table.HeaderCell>*/}
                        <Table.HeaderCell>
                            Produto
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Produtor
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Categoria
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Descrição
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Preço
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Quantidade Disponível
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Unidade
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Seu Pedido
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Preço Final
                        </Table.HeaderCell>
                        <Table.HeaderCell>

                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {ofertas.filter(oferta => !filtrarOfertas.includes(oferta._id)).map(oferta => (
                        <Table.Row className={desabilitar[oferta._id]? "confirmando-pedido" : "fazer-pedidos-row" } key={oferta._id}>
                            {/*<Table.Cell>
                                {oferta._id}
                            </Table.Cell>*/}
                            <Table.Cell>
                                <Popup
                                    content={
                                        <Table.HeaderCell  className="semana-pop">
                                            {/*Alternativamente eu posso deixar a
                                            descrição do produto aqui nesse popup.
                                            Cabe muita coisa, então descrições longas
                                            são bem vindas.*/}

                                            {oferta.produto_produtor.produto.descricao}
                                        </Table.HeaderCell>
                                    }
                                    trigger={
                                        <p>{oferta.produto_produtor.produto.produto}</p>
                                    }
                                    position='bottom right'
                                />
                            </Table.Cell>
                            <Table.Cell>
                                {oferta.produto_produtor.produtor.produtor}
                            </Table.Cell>
                            <Table.Cell>
                                {oferta.produto_produtor.produto.categoria.categoria}
                            </Table.Cell>
                            <Table.Cell>
                                {oferta.produto_produtor.produto.descricao}
                            </Table.Cell>
                            <Table.Cell className="nowrap">
                                R$ {oferta.preco}
                            </Table.Cell>
                            <Table.Cell>
                                {props.diminuirEstoque.map(produto => produto._idOferta).includes(oferta._id) ?
                                    oferta.oferta - props.diminuirEstoque.filter(produto => produto._idOferta === oferta._id).map(produto => produto.demandaTotal)
                                    :
                                    oferta.oferta }
                            </Table.Cell>
                            <Table.Cell className="nowrap">
                                {oferta.produto_produtor.produto.quantia} {oferta.produto_produtor.produto.unidade.unidade}
                            </Table.Cell>
                            <Table.Cell className="pedido-pedido">
                                <Input
                                    type="number"
                                    max={props.diminuirEstoque.map(produto => produto._idOferta).includes(oferta._id) ?
                                    oferta.oferta - props.diminuirEstoque.filter(produto => produto._idOferta === oferta._id).map(produto => produto.demandaTotal)
                                    :
                                    oferta.oferta }
                                    min={0}
                                    placeholder={0}
                                    value={demanda[oferta._id]}
                                    onChange={e => {
                                          if (parseFloat(e.target.value) !== demanda[oferta._id]) {
                                              if(props.diminuirEstoque.map(produto => produto._idOferta).includes(oferta._id)){
                                                  let max = (oferta.oferta - props.diminuirEstoque.filter(produto => produto._idOferta === oferta._id).map(produto => produto.demandaTotal))
                                                  if (parseFloat(e.target.value) <= max && parseFloat(e.target.value) >= 0) {
                                                      handleEntrada(e, oferta._id)
                                                  } else {
                                                      handleEntrada(0, oferta._id)
                                                  }
                                              } else {
                                                  let max = oferta.oferta
                                                  if (parseFloat(e.target.value) <= max && parseFloat(e.target.value) >= 0) {
                                                      handleEntrada(e, oferta._id)
                                                  } else {
                                                      handleEntrada(0, oferta._id)
                                                  }
                                              }

                                          }

                                      }
                                    }
                                />
                            </Table.Cell>
                            <Table.Cell>
                                { isNaN(demanda[oferta._id]) ? 0 : 'R$' + (oferta.preco * demanda[oferta._id]) }
                            </Table.Cell>
                            <Table.Cell>
                                {desabilitar[oferta._id] ?
                                    <button>
                                        Comprar
                                    </button>
                                    :
                                    <button onClick={() => handleComprar(demanda[oferta._id], oferta)}>
                                        Comprar
                                    </button>
                                }
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>

                <Table.Footer>
                    <Table.Row>

                    </Table.Row>
                </Table.Footer>
            </Table>
        </Fragment>
    );
}