import React, {useEffect} from "react";
import { useAuth0 } from "../../react-auth0-spa";

import NavBar from "../../components/NavBar";
import PerfilCard from "./PerfilCard";
import EditarPerfil from "./EditarPerfil";

import './styles.css';


import nagoCasa from "../../assets/NAGO-logo-casa.png";
import logoImg35 from "../../assets/NAGO-logo-16.png";
import ufjf from "../../assets/logo_ufjf_campus_gv.png";
import cat from "../../assets/cat-38.png";
import spinner from "../../assets/Spinner-1.6s-194px.svg";
import Layout from "../../components/layout";
import {useHistory} from "react-router-dom";
import LogosTopo from "../../components/logosTopo";


export default function Principal() {
    const { loading, user } = useAuth0();
    const history = useHistory();

    useEffect(() => {
        document.title = "Perfil - Rede Tamanduá"
    },[])

    function handleLoading() {
        history.push('/');
    }

    if (loading || !user) {
        return (
            <div className="carregando">
                Carregando...
                <img src={spinner} alt="loading"/>
                ...se demorar muito é possível
                que não tem autorização ou que
                você esqueceu de entrar na sua
                conta
                <button onClick={() => handleLoading()} type="button" className="buttonloading">
                    Voltar para fazer o login...
                </button>
            </div>

        );
    }

    return(

       <Layout>
            <div id="outer-container">

                <NavBar noOverlay pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" }/>

                <main id="page-wrap">
                    {/*<div className='paginaPrincipal'>

                        <section className="form">
                            <img src={cat} alt="CAT" width={100}/>
                            <img src={logoImg35} alt="Nagô" width={100}/>
                            <img src={ufjf} alt="UFJF" width={200}/>

                            <form>
                                <h3>Dados Permanentes Pessoais</h3>
                                <h4>Utilizados para entrar no site</h4>
                                <PerfilCard/>
                            </form>
                        </section>
                        <div className="agradecimento">
                            <h1>Agradecemos pela visita</h1>
                            <img src={nagoCasa} alt="Casa Nagô" width={500}/>
                        </div>
                    </div>*/}
                        <LogosTopo/>

                        <div className="editarPerfilContainer">
                            <EditarPerfil classname='editarPerfil'/>
                        </div>
                </main>
            </div>
        </Layout>

    );
}