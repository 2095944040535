import React, { Fragment, useState, useEffect } from 'react';

//Para trabalhar com a tabela
import {Modal, Card} from 'semantic-ui-react';

//Para trabalhar com o dropdown
import Select from 'react-select';

//Para conectar com o backend
import api from '../../../services/api';

import './styles.css';

export default function TabelaUsuarios () {

    //ReiniciarPágina
    const [restart, setRestart] = useState(false);
    const [usuarios, setUsuarios] = useState([]);
    const [prossumidores, setProssumidores] = useState([]);
    const [produtores, setProdutores] = useState([]);
    const [classes, setClasses] = useState([]);
    const [novaClasse, setNovaClasse] = useState([]);
    const [nome, setNome] = useState([]);
    const [email, setEmail] = useState([]);
    const [endereco, setEndereco] = useState([]);
    const [telefone, setTelefone] = useState([]);
    const [idAuth0, setIdAuth0] = useState([]);
    const [idUsuario, setIdUsuario] = useState([]);
    const [idProdutor, setIdProdutor] = useState([]);
    const [idProssumidor, setIdProssumidor] = useState([]);
    const [mostrar, setMostrar] = useState(false);

    useEffect(() => {
        try {
            api.get('/usuarios', {})
                .then(response => {
                    setUsuarios(response.data)
                })
        } catch (e) {
            alert(e)
        }

        try {
            api.get('/prossumidores', {})
                .then(response => {
                    setProssumidores(response.data)
                })
        } catch (e) {
            alert(e)
        }

        try {
            api.get('/produtores', {})
                .then(response => {
                    setProdutores(response.data)
                })
        } catch (e) {
            alert(e)
        }

        try {
            api.get('/classes', {})
                .then(response => {
                    setClasses(response.data)
                })
        } catch (e) {
            alert(e)
        }
    }, [restart]);


    //Funções dos botões
    async function handleAtivarProdutor(id) {
        try {
            await api.put('/produtores/' + id, {
                'ativo': true
            })

            setRestart(!restart)
        } catch (e) {
            alert(e)
        }
    }

    async function handleDesativarProdutor(id) {
        try {
            await api.put('/produtores/' + id, {
                'ativo': false
            })
            setRestart(!restart)
        } catch (e) {
            alert(e)
        }
    }

    async function handleAtivarProssumidor(id) {
        try {
            await api.put('/prossumidores/' + id, {
                'ativo': true
            })
            setRestart(!restart)
        } catch (e) {
            alert(e)
        }
    }

    async function handleDesativarProssumidor(id) {
        try {
            await api.put('/prossumidores/' + id, {
                'ativo': false
            })
            setRestart(!restart)
        } catch (e) {
            alert(e)
        }
    }

    async function handleAlterarDados () {
        try {
            await api.put('/usuarios/' + idUsuario, {
                'usuario': nome,
                'classe': novaClasse.value,
                'telefone': telefone,
                'endereco': endereco,
                'email': email
            }).catch(e => alert('Não foi possível alterar o usuário: ' + e))

            if (idProdutor) {
                await api.put('/produtores/' + idProdutor, {
                    'produtor': nome
                }).catch(e => alert('Não foi possível atualizar o nome nos dados de produtores: ' + e))
            }

            if (idProssumidor) {
                await api.put('/prossumidores/' + idProssumidor, {
                    'prossumidor': nome
                }).catch(e => alert('Não foi possível atualizar o nome nos dados de prossumidores: ' + e))
            }



        } catch (e) {
            alert(e)
        }
        setNome([])
        setNovaClasse([])
        setTelefone([])
        setEmail([])
        setEndereco([])
        setIdUsuario([])
        setIdProdutor([])
        setIdProssumidor([])
        setIdAuth0([])
        setRestart(!restart)
    }

    async function handleConfirmarProdutor() {
        try {
          await api.post(`produtores/`, {
            'produtor': nome,
            'auth0': idAuth0
                });

        } catch (e) {
            alert(e);
        }

        try {
          const produtor = await api.get(`produtores/` + idAuth0);

          await api.put(`usuarios/` + idUsuario, {
            'produtor': produtor.data._id
            });

          await setRestart(!restart);

        } catch (e) {
            alert(e);
        }

        setIdProdutor(true)
        setRestart(!restart)
    }

    async function handleConfirmarProssumidor() {
        try {
          await api.post(`prossumidores/`, {
            'prossumidor': nome,
            'auth0': idAuth0
                });

        } catch (e) {
            alert(e);
        }

        try {
          const prossumidor = await api.get(`prossumidores/` + idAuth0);

          await api.put(`usuarios/` + idUsuario, {
            'prossumidor': prossumidor.data._id
            });

          await setRestart(!restart);

        } catch (e) {
            alert(e);
        }

        setIdProssumidor(true)
        setRestart(!restart)
    }

    return(
        <Fragment>
            <div>
                <div>
                    <Card.Group className="usuarios-container">
                        {usuarios.map(usuario => (
                            <Card key={usuario._id}>
                              <Card.Content className='conteudo-usuarios'>
                                <Card.Header>{usuario.usuario}</Card.Header>
                                <h3 className="classe">Classe: {
                                    classes
                                        .filter(classe => classe._id === usuario?.classe)
                                        .map(classe => classe.classe)
                                }</h3>
                                <Card.Meta className='id-usuario'>
                                    <span>ID auth0: {usuario.auth0}</span>
                                    <span>ID Usuário: {usuario._id}</span>
                                    {prossumidores.map(prossumidores => prossumidores.auth0).includes(usuario.auth0) ?
                                        <span>ID Prossumidor: {prossumidores.filter(prossumidor => prossumidor.auth0 === usuario.auth0).map(prossumidor => prossumidor._id) }</span>
                                    :
                                        ''
                                    }
                                    {produtores.map(produtores => produtores.auth0).includes(usuario.auth0) ?
                                        <span>ID Produtor: {produtores.filter(produtor => produtor.auth0 === usuario.auth0).map(produtor => produtor._id) }</span>
                                    :
                                        ''
                                    }
                                </Card.Meta>
                                <Card.Description className='descricao-usuario'>
                                    {usuario.email ?
                                        <span>Email: {usuario.email}</span>
                                    :
                                        ''
                                    }
                                    {usuario.endereco ?
                                        <span>Endereço: {usuario.endereco}</span>
                                    :
                                        ''
                                    }
                                    {usuario.telefone ?
                                        <span>Telefone: {usuario.telefone}</span>
                                    :
                                        ''
                                    }
                                </Card.Description>
                              </Card.Content>
                              <Card.Content className="botoes-usuarios">
                                  <button className='botao-editar-usuario'
                                      onClick={() => {
                                      setMostrar(true)
                                      setNome(usuario.usuario)
                                      setNovaClasse({
                                          label:
                                          classes
                                              .filter(classe => classe._id === usuario?.classe)
                                              .map(classe => classe.classe)
                                      })
                                      setTelefone(usuario?.telefone)
                                      setEmail(usuario.email)
                                      setEndereco(usuario?.endereco)
                                      setIdUsuario(usuario._id)
                                      setIdProssumidor(usuario?.prossumidor)
                                      setIdProdutor(usuario?.produtor)
                                      setIdAuth0(usuario.auth0)
                                  }}>
                                    Editar Dados
                                  </button>
                                <div className='botao-ativar'>
                                    {prossumidores.map(prossumidores => prossumidores.auth0).includes(usuario.auth0) ?
                                        <div className='botao-prossumidor'>
                                            {prossumidores
                                            .filter(prossumidores => prossumidores.auth0 === usuario.auth0)
                                            .map(prossumidor => prossumidor.ativo)[0] ?
                                                <button className='botao-ativo' onClick={() => handleDesativarProssumidor(
                                                    prossumidores
                                                        .filter(prossumidores => prossumidores.auth0 === usuario.auth0)
                                                        .map(prossumidor => prossumidor._id)[0]
                                                )}>
                                                    Prossumidor Ativado
                                                </button>
                                            :
                                                <button className='botao-desativo' onClick={() => handleAtivarProssumidor(
                                                    prossumidores
                                                        .filter(prossumidores => prossumidores.auth0 === usuario.auth0)
                                                        .map(prossumidor => prossumidor._id)[0]
                                                )}>
                                                    Prossumidor Desativado
                                                </button>
                                            }
                                        </div>
                                    :
                                        ''
                                    }
                                    {produtores.map(produtores => produtores.auth0).includes(usuario.auth0) ?
                                        <div className='botao-produtor'>
                                            {produtores
                                            .filter(produtores => produtores.auth0 === usuario.auth0)
                                            .map(produtor => produtor.ativo)[0] ?
                                                <button className='botao-ativo' onClick={() => handleDesativarProdutor(
                                                    produtores
                                                        .filter(produtores => produtores.auth0 === usuario.auth0)
                                                        .map(produtor => produtor._id)[0]
                                                )}>
                                                    Produtor Ativado
                                                </button>
                                            :
                                                <button className='botao-desativo' onClick={() => handleAtivarProdutor(
                                                    produtores
                                                        .filter(produtores => produtores.auth0 === usuario.auth0)
                                                        .map(produtor => produtor._id)[0]
                                                )}>
                                                    Produtor Desativado
                                                </button>
                                            }
                                        </div>
                                    :
                                        ''
                                    }
                                </div>
                              </Card.Content>
                            </Card>
                        ))}
                        {mostrar && <div className="modaloverlay"/>}
                                <Modal className="modal"
                                  open={mostrar}
                                  closeOnEscape={true}
                                  closeOnDimmerClick={false}
                                  //onClose={}
                                >
                                  <Card>
                                      <Card.Content>
                                        <Card.Header>Alterar dados de {nome}</Card.Header>
                                        <Card.Description className="editar-usuario-container">
                                            Nome: <input className="editar-usuario"
                                                placeholder='Corrigir nome...'
                                                value={nome}
                                                onChange={e => setNome(e.target.value)}
                                            />
                                        </Card.Description>
                                        <Card.Description className="editar-usuario-container">
                                            Email: <input className="editar-usuario"
                                                placeholder='Corrigir nome...'
                                                value={email}
                                                onChange={e => setEmail(e.target.value)}
                                            />
                                        </Card.Description>
                                        <Card.Description className="editar-usuario-container">
                                            Endereço: <input className="editar-usuario"
                                                placeholder='Corrigir nome...'
                                                value={endereco}
                                                onChange={e => setEndereco(e.target.value)}
                                            />
                                        </Card.Description>
                                        <Card.Description className="editar-usuario-container">
                                            Telefone: <input className="editar-usuario"
                                                placeholder='Corrigir nome...'
                                                value={telefone}
                                                onChange={e => setTelefone(e.target.value)}
                                            />
                                        </Card.Description>
                                        <Card.Description className="usuario-select-classe-container">
                                            Classe:   <Select className="trocar-classe-usuário"
                                                 options = {classes.map(classe => (
                                                     {value: classe._id, label: classe.classe}
                                                 ))}
                                                 placeholder={classes
                                                     .filter(classe => classe._id === novaClasse?.label)
                                                     .map(classe => classe.classe)
                                                 }
                                                 value={novaClasse}
                                                 onChange={val => {
                                                     setNovaClasse(val)
                                                 }}
                                            />
                                        </Card.Description>
                                      </Card.Content>
                                      <Card.Content>
                                        <div className='editar-usuario-botoes-container'>
                                          <button onClick={() => {
                                              setMostrar(false)
                                              handleAlterarDados()
                                          }}>
                                              Alterar dados
                                          </button>
                                          <button onClick={() => {
                                              setMostrar(false)
                                              setNome([])
                                              setNovaClasse([])
                                              setTelefone([])
                                              setEmail([])
                                              setEndereco([])
                                              setIdUsuario([])
                                              setIdAuth0([])
                                              setIdProdutor([])
                                              setIdProdutor([])
                                          }}>
                                              Não alterar
                                          </button>
                                        </div>
                                      </Card.Content>
                                      <Card.Content>
                                        <div className='editar-usuario-botoes-container'>
                                            {idProssumidor? '' :
                                              <button onClick={() => {
                                                  handleConfirmarProssumidor()
                                              }}>
                                                  Confirmar que usuário é um prossumidor
                                              </button>
                                            }
                                            {idProdutor? '' :
                                              <button onClick={() => {
                                                  handleConfirmarProdutor()
                                              }}>
                                                  Confirmar que usuário é um produtor
                                              </button>
                                            }
                                        </div>
                                      </Card.Content>
                                    </Card>
                                </Modal>
                    </Card.Group>
                </div>
            </div>
        </Fragment>
    );
}