import React, {useEffect, useState} from "react";


import './styles.css';

import NavBarAdmin from "../../components/NavBarAdmin";
import {useAuth0} from "../../react-auth0-spa";
import spinner from "../../assets/Spinner-1.6s-194px.svg";
import Layout from "../../components/layout";
import {useHistory} from "react-router-dom";
import LogosTopo from "../../components/logosTopo";
import TabelaSemanas from "./TabelaSemanas";
import api from "../../services/api";


export default function Administrar() {
    const {loading, user} = useAuth0();
    const history = useHistory();
    const [usuario, setUsuario] = useState(false);

    useEffect(() => {
        document.title = "Administrar - Rede Tamanduá"
    },[])

    useEffect(() => {
        api.get('usuarios/' + user?.sub)
            .then(response => {
                if((JSON.stringify(response.data) !== JSON.stringify(usuario))){
                    setUsuario(response.data)
                }
            })
            .catch(err => alert(err))
    }, [user, usuario])

    function handleLoading() {
        history.push('/');
    }

    if (loading || !user || !(usuario?.classe === "5ec720e73c9eef00047d9a42" || usuario?.classe === "5ec720fb3c9eef00047d9a43")) {
        return (
            <div className="carregando">
                Carregando...
                <img src={spinner} alt="loading"/>
                ...se demorar muito é possível
                que não tem autorização ou que
                você esqueceu de entrar na sua
                conta
                <button onClick={() => handleLoading()} type="button" className="buttonloading">
                    Voltar para fazer o login...
                </button>
            </div>

        );
    }

    return(
        <Layout>
            <div id="outer-container">

                <NavBarAdmin noOverlay pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" }/>

                <main id="page-wrap">
                    <LogosTopo/>
                    <div className="tabela-semana-container">
                        <TabelaSemanas classname="tabela-semana" />
                    </div>
                </main>
            </div>
        </Layout>
    );
}