import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
import NavBar from "../../components/NavBar";
import { useAuth0 } from "../../react-auth0-spa";

import './styles.css';

import nagoCasa from "../../assets/NAGO-logo-casa.png";
import ufjf from "../../assets/logo_ufjf_campus_gv.png";
import cat from "../../assets/cat-38.png";
import redeLogo from "../../assets/redeLogo3.png";
import spinner from "../../assets/Spinner-1.6s-194px.svg";
import Layout from "../../components/layout";

export default function Login() {

    const { isAuthenticated, loading } = useAuth0();

    useEffect(() => {
        document.title = "Rede Tamanduá"
    },[])

    if (loading) {
        return (
            <div className="carregando">
                Carregando...
                <img src={spinner} alt="loading"/>
                ...se demorar muito é possível
                que não tem autorização ou que
                você esqueceu de entrar na sua
                conta
            </div>
        );
    }

    return(
        <Layout>
            <div id="outer-container">

                <NavBar noOverlay pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" }/>

                <main id="page-wrap">

                    <div className="logon-container">

                        <section className="form">
                            <img src={cat} alt="CAT" width={100}/>
                            <img src={nagoCasa} alt="Nagô" width={100}/>
                            <img src={ufjf} alt="UFJF" width={200}/>

                            <form>
                                {!isAuthenticated && (
                                    <h1>Entre na sua conta ou se cadastre no menu ao lado!</h1>
                                )}
                                {isAuthenticated && (
                                    <h1>Seja bem-vindo(a)!</h1>
                                )}

                                <Link className="back-link" to="/privacypolicy">
                                    <FaInfoCircle size={16} color="#FF0000"/>
                                    Política de Privacidade
                                </Link>

                            </form>
                        </section>

                        <img src={redeLogo} alt="Rede" height={400}/>
                    </div>
                </main>
            </div>
        </Layout>
    );
}