import React from "react";

import EnglishPolicy from "./English";
import PortuguesePolicy from "./Portuguese";

class Traduzir extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }

    ToggleCLick = () => {
        this.setState({ show: !this.state.show })
    }

    render() {

        return (
            <div>
                <button className="button" onClick={this.ToggleCLick}>
                    { this.state.show ? 'Mudar para a versão em Português' : 'Change to the English version' }
                </button>
                { this.state.show ? <EnglishPolicy/> :  <PortuguesePolicy/> }
            </div>

        );
    }
}

export default Traduzir;