import React, {useEffect, useState} from 'react'
import { Table } from 'semantic-ui-react';
import "../../../components/table.css";
import api from "../../../services/api";

export default function TabelaCategorias () {
    const [categorias, setCategorias] = useState([]);
    const [nome, setNome] = useState([]);
    const [renomear, setRenomear] = useState([]);
    const [idAtual, setIdAtual] = useState([]);
    const [mostrar, setMostrar] = useState(false);
    const [restart,setRestart] = useState(false);

    useEffect(() => {
    api.get('./categorias', {})
        .then(response => {
            setCategorias(response.data)
        })
    }, [restart]);


    async function handleDeleteCategoria(id){

        try {
            await api.delete(`categorias/${id}`, {

            });

            setCategorias(categorias.filter(categoria => categoria._id !== id));
        } catch (id) {
            alert(id)
        }
    }

    async function handleRenomearCategoria(id){

        if (renomear === {}) {
            alert("Precisa digitar um nome corrigido pra categoria")
        }

        try {
            await api.put(`categorias/${id}`, {
                "categoria": renomear,
            });

            setRenomear([]);
            setRestart(!restart);
        } catch (e) {
            alert("Precisa digitar um nome corrigido pra categoria")
        }
    }

    async function handleCreateCategoria(e){
        e.preventDefault();

        try {
            await api.post(`categorias`, {
                "categoria": nome,
            }, {

            });

            setNome([]);
            setRestart(!restart);
        } catch (e) {
            alert("Precisa digitar um nome pra categoria nova")
        }
    }

    return(
        <Table compact celled definition className="categoria-table">
            <Table.Header className="categoria-tableheader">
                <Table.Row className="categoria-row">
                    <Table.HeaderCell className="categoria-headercell">
                            Criar Categoria
                    </Table.HeaderCell>
                    <Table.HeaderCell className="categoria-headercell">
                        <input
                            placeholder='Criar nova categoria...'
                            value={nome}
                            onChange={e => setNome(e.target.value)}
                        />
                    </Table.HeaderCell>
                    <button className="criar-categoria" onClick={handleCreateCategoria}>
                        <Table.HeaderCell className="categoria-headercell">
                            Enviar Categoria
                        </Table.HeaderCell>
                    </button>
              </Table.Row>
            </Table.Header>

            <Table.Header className="categoria-tableheader">
                <Table.Row className="categoria-row">
                    <Table.HeaderCell className="categoria-headercell">ID</Table.HeaderCell>
                    <Table.HeaderCell className="categoria-headercell">Categoria</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body className="categoria-tablebody">
                {categorias.map(categoria => (
                    <Table.Row className="categoria-row">
                        <Table.Cell className="categoria-cell">{categoria._id}</Table.Cell>
                        <Table.Cell className="categoria-cell">{categoria.categoria}</Table.Cell>
                        <Table.Cell>
                            <button onClick={() => handleDeleteCategoria(categoria._id)} className="deletar-categoria">
                                Deletar
                            </button>
                        </Table.Cell>
                        <Table.Cell className="categoria-cell">
                            <button onClick={() => {
                                setIdAtual(categoria._id);
                                setMostrar(!mostrar);
                                setRenomear([]);
                            }} className="renomear-categoria">
                                Corrigir
                            </button>
                        </Table.Cell>
                        { mostrar && (idAtual === categoria._id) ?
                            <Table.Cell className="caixa-renomear-categoria">
                                <input
                                    placeholder='Corrigir categoria...'
                                    value={renomear}
                                    onChange={e => setRenomear(e.target.value)}
                                />
                                <button onClick={() => handleRenomearCategoria(categoria._id)} className="enviar-renomear-categoria">
                                    Enviar
                                </button>
                            </Table.Cell>: ''
                        }
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
}
