import React, {useEffect, useState, Fragment} from 'react'
import { Table } from 'semantic-ui-react';
import "./editarperfil.css";
import api from "../../services/api";
import {useAuth0} from "../../react-auth0-spa";
import "./styles.css"

export default function EditarPerfil () {
    const [perfil, setPerfil] = useState([]);
    const [renomear, setRenomear] = useState([]);
    const [idAtual, setIdAtual] = useState([]);
    const [mostrar, setMostrar] = useState(false);
    const [restart, setRestart] = useState(false);
    const [usuario, setUsuario] = useState([]);
    const { user } = useAuth0();


    useEffect(() => {
        api.get('./usuarios', {})
            .then(response => {
                setPerfil(response.data)
            });
        api.get(`usuarios/` + user.sub)
            .then(res => {setUsuario(res.data)})
            .catch(() => {setUsuario([])});
    }, [restart, user.sub]);

    const eusuario = usuario?.usuario == null;
    const eprodutor = usuario?.produtor == null;
    const eprossumidor = usuario?.prossumidor == null;

    async function handleConfirmarUsuario() {
        try {
          await api.post(`usuarios/`, {
            'usuario': user.nickname,
            'auth0': user.sub,
            'email': user.email
                });

        } catch (e) {
            alert(e);
        }

        await setRestart(!restart);
    }

/*    async function handleConfirmarProdutor() {
        try {
          await api.post(`produtores/`, {
            'produtor': user.nickname,
            'auth0': user.sub
                });

        } catch (e) {
            alert(e);
        }

        try {
          const produtor = await api.get(`produtores/` + user.sub);
          const usuario = await api.get(`usuarios/` + user.sub);

          await api.put(`usuarios/` + usuario.data._id, {
            'produtor': produtor.data._id
            });

          await setRestart(!restart);

        } catch (e) {
            alert(e);
        }
    }

    async function handleConfirmarProssumidor() {
        try {
          await api.post(`prossumidores/`, {
            'prossumidor': user.nickname,
            'auth0': user.sub
                });

        } catch (e) {
            alert(e);
        }

        try {
          const prossumidor = await api.get(`prossumidores/` + user.sub);
          const usuario = await api.get(`usuarios/` + user.sub);

          await api.put(`usuarios/` + usuario.data._id, {
            'prossumidor': prossumidor.data._id
            });

          await setRestart(!restart);

        } catch (e) {
            alert(e);
        }
    }*/

    async function handleRenomearPerfil(id, entrada){

        if (renomear === {}) {
            alert("Não pode enviar uma solicitação vazia")
        }

        try {
            if (entrada === 'usuario') {
                await api.put(`usuarios/${id}`, {
                    'usuario': renomear
                });

                const produtor = await api.get(`produtores/${usuario.auth0}`)
                    .then(response => {
                        let dados = response.data
                        if (!(dados?.auth0 == null) && dados.auth0 === usuario.auth0) {
                            return dados._id
                        } else {
                            return false
                        }
                    })

                if(produtor) {
                    await api.put(`produtores/${produtor}`,{
                        'produtor': renomear
                    })
                }

                const prossumidor = await api.get(`prossumidores/${usuario.auth0}`)
                    .then(response => {
                        let dados = response.data
                        if (!(dados?.auth0 == null) && dados.auth0 === usuario.auth0) {
                            return dados._id
                        } else {
                            return false
                        }
                    })

                if(prossumidor) {
                    await api.put(`prossumidores/${prossumidor}`,{
                        'prossumidor': renomear
                    })
                }

            }

            if (entrada === 'email') {
                await api.put(`usuarios/${id}`, {
                    'email': renomear
                });
            }

            if (entrada === 'telefone') {
                await api.put(`usuarios/${id}`, {
                    'telefone': renomear
                });
            }

            if (entrada === 'endereco') {
                await api.put(`usuarios/${id}`, {
                    'endereco': renomear
                });
            }

            await setRenomear([]);
            await setRestart(!restart);
        } catch (e) {
            alert(e)
        }
    }

    return(
        <Fragment>
            <h1 className='tituloPrincipal'>
                Agradecemos pela visita
            </h1>
            <div className="envio-perfis">
                    {eusuario ?
                        <div>
                        <h2>Por favor, clique nesse botão para certificarmos que tem interesse em participar da rede e que possamos utilizar os seus dados</h2>
                        <button onClick={() => (handleConfirmarUsuario())}>
                            Aceito enviar meus
                            dados à Rede Tamanduá
                        </button>
                        </div>
                     :
                        <div className='apos-confirmar'>
                            <div className="concedi-meus-dados-botao">
                                Concedi meus dados
                            </div>
                            {eprossumidor ?
                                <div className='nao-concedi-meus-dados-botao'>
                                    Prossumidor Não Confirmado
                                </div>
                             :
                                <div className="concedi-meus-dados-botao">
                                    Prossumidor Confirmado
                                </div>
                            }
                            {eprodutor ?
                                <div className='nao-concedi-meus-dados-botao'>
                                    Produtor não Confirmado
                                </div>
                             :
                                <div className="concedi-meus-dados-botao">
                                    Produtor Confirmado
                                </div>
                            }
                        </div>
                    }
            </div>
            {perfil.filter(perfil => perfil.auth0 === user.sub).map(perfil => (
                <Table compact celled definition className="perfil-table">
                    <Table.Header className="perfil-tableheader">
                        <Table.Row className="perfil-row">
                            <Table.HeaderCell colSpan='3'>
                                <h1 className="perfil-titulo">Dados utilizados pela Rede Tamanduá</h1>
                            </Table.HeaderCell></Table.Row>
                    </Table.Header>

                    <Table.Header className="perfil-tableheader">
                        <Table.Row className="perfil-row">
                            <Table.HeaderCell className="perfil-headercell" >Índices: </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body className="perfil-tablebody">
                            <div className="conteudo-perfil">
                                <Table.Row className="perfil-row">
                                    <Table.Cell className="perfil-cell">Id: </Table.Cell>
                                    <Table.Cell className="perfil-cell">{perfil._id}</Table.Cell>
                                </Table.Row>
                                {perfil.prossumidor ?
                                <Table.Row className="perfil-row">
                                    <Table.Cell className="perfil-cell">Prossumidor: </Table.Cell>
                                    <Table.Cell className="perfil-cell">{perfil.prossumidor}</Table.Cell>
                                </Table.Row> : ''}
                                {perfil.produtor ?
                                <Table.Row className="perfil-row">
                                    <Table.Cell className="perfil-cell">Produtor: </Table.Cell>
                                    <Table.Cell className="perfil-cell">{perfil.produtor}</Table.Cell>
                                </Table.Row> : ''}
                                <Table.Row className="perfil-row">
                                    <Table.Cell className="perfil-cell">auth0: </Table.Cell>
                                    <Table.Cell className="perfil-cell">{perfil.auth0}</Table.Cell>
                                </Table.Row>
                                <Table.Row className="perfil-row">
                                    <Table.Cell className="perfil-cell">Nome: </Table.Cell>
                                    <Table.Cell className="perfil-cell">{perfil.usuario}</Table.Cell>
                                    <Table.Cell className="perfil-cell">
                                        <button onClick={() => {
                                            setIdAtual('usuario');
                                            setMostrar(!mostrar);
                                            setRenomear([]);
                                        }} className="renomear-perfil">
                                            Corrigir
                                        </button>
                                    </Table.Cell>
                                    { mostrar && (idAtual === 'usuario') ?
                                        <Table.Cell className="caixa-renomear-perfil">
                                            <input
                                                placeholder='Corrigir nome...'
                                                value={renomear}
                                                onChange={e => setRenomear(e.target.value)}
                                            />
                                            <button onClick={() => handleRenomearPerfil(perfil._id, 'usuario')} className="enviar-renomear-perfil">
                                                Enviar
                                            </button>
                                        </Table.Cell>: ''
                                    }
                                </Table.Row>
                                <Table.Row className="perfil-row">
                                    <Table.Cell className="perfil-cell">E-mail: </Table.Cell>
                                    <Table.Cell className="perfil-cell">{perfil.email}</Table.Cell>
                                    <Table.Cell className="perfil-cell">
                                        <button onClick={() => {
                                            setIdAtual('email');
                                            setMostrar(!mostrar);
                                            setRenomear([]);
                                        }} className="renomear-perfil">
                                            Corrigir
                                        </button>
                                    </Table.Cell>
                                    { mostrar && (idAtual === 'email') ?
                                        <Table.Cell className="caixa-renomear-perfil">
                                            <input
                                                placeholder='Corrigir e-mail...'
                                                value={renomear}
                                                onChange={e => setRenomear(e.target.value)}
                                            />
                                            <button onClick={() => handleRenomearPerfil(perfil._id, 'email')} className="enviar-renomear-perfil">
                                                Enviar
                                            </button>
                                        </Table.Cell>: ''
                                    }
                                </Table.Row>
                                <Table.Row className="perfil-row">
                                    <Table.Cell className="perfil-cell">Telefone: </Table.Cell>
                                    <Table.Cell className="perfil-cell">{perfil.telefone}</Table.Cell>
                                    <Table.Cell className="perfil-cell">
                                        <button onClick={() => {
                                            setIdAtual('telefone');
                                            setMostrar(!mostrar);
                                            setRenomear([]);
                                        }} className="renomear-perfil">
                                            Corrigir
                                        </button>
                                    </Table.Cell>
                                    { mostrar && (idAtual === 'telefone') ?
                                        <Table.Cell className="caixa-renomear-perfil">
                                            <input
                                                placeholder='Corrigir telefone...'
                                                value={renomear}
                                                onChange={e => setRenomear(e.target.value)}
                                            />
                                            <button onClick={() => handleRenomearPerfil(perfil._id, 'telefone')} className="enviar-renomear-perfil">
                                                Enviar
                                            </button>
                                        </Table.Cell>: ''
                                    }
                                </Table.Row>
                                <Table.Row className="perfil-row">
                                    <Table.Cell className="perfil-cell">Endereço: </Table.Cell>
                                    <Table.Cell className="perfil-cell">{perfil.endereco}</Table.Cell>
                                    <Table.Cell className="perfil-cell">
                                        <button onClick={() => {
                                            setIdAtual('endereço');
                                            setMostrar(!mostrar);
                                            setRenomear([]);
                                        }} className="renomear-perfil">
                                            Corrigir
                                        </button>
                                    </Table.Cell>
                                    { mostrar && (idAtual === 'endereço') ?
                                        <Table.Cell className="caixa-renomear-perfil">
                                            <input
                                                placeholder='Corrigir endereço...'
                                                value={renomear}
                                                onChange={e => setRenomear(e.target.value)}
                                            />
                                            <button onClick={() => handleRenomearPerfil(perfil._id, 'endereco')} className="enviar-renomear-perfil">
                                                Enviar
                                            </button>
                                        </Table.Cell>: ''
                                    }
                                </Table.Row>
                            </div>
                    </Table.Body>

                    <Table.Footer>
                        <Table.Row>

                        </Table.Row>
                    </Table.Footer>
                </Table>
            ))}
        </Fragment>
    );
}
