import React, { Fragment } from "react";
import {Table} from 'semantic-ui-react';

import "./styles.css"
import DadosExtrasDePedido from "../Pedido/DadosExtrasDePedido";

export default function ResumoProssumidores(props) {

    function handleData(JSON) {
        return(
            Intl.DateTimeFormat("pt-Br", {
                year: "numeric",
                month: "numeric",
                day: "2-digit",
                hour: "numeric",
                minute: "numeric"
            }).format(new Date(JSON))
        )
    }

    function handleRefresh2() {
        props.refresh()
    }

    return(
        <Fragment>
            <pre> </pre>
            <h1> Resumo de Pedidos dos Prossumidores </h1>

            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            ID
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Criado/Editado
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Prossumidor
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Entrega +
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Produtos
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            + Taxa
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            = Total
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Entrega
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {props.recibos.map(recibo =>
                        <Table.Row key={recibo._id}>
                            <Table.Cell>
                                {recibo._id}
                            </Table.Cell>
                            <Table.Cell className="datas-resumo">
                                <span>
                                    Criado: {
                                        handleData(recibo.createdAt)
                                    }
                                </span>
                                <span>
                                    Editado: {
                                     handleData(recibo.updatedAt)
                                    }
                                </span>
                            </Table.Cell>
                            <Table.Cell>
                                {recibo.prossumidor.prossumidor}
                            </Table.Cell>
                            <Table.Cell>
                                {(recibo?.entrega === 0 || recibo.entrega === null)?
                                    <span className="resumo-table-nao">
                                        Não - R$ 0
                                    </span>
                                :
                                    <span>
                                        R$ {Number(recibo.entrega).toFixed(2)}
                                    </span>
                                }
                            </Table.Cell>
                            <Table.Cell>
                                R$ {
                                    Number(
                                        recibo.pedido
                                            .reduce((resultado, item) =>
                                                resultado + (item.oferta.preco * item.demanda)
                                            , 0)).toFixed(2)
                                    }
                            </Table.Cell>
                            <Table.Cell className="taxas-resumo">
                                <span>
                                    {recibo.taxa}%
                                </span>
                                <span>
                                    R$ {
                                            Number(
                                                recibo.pedido
                                                    .reduce((resultado, item) =>
                                                        resultado + (item.oferta.preco * item.demanda)
                                                    , 0)
                                                    * (recibo.taxa/100)
                                            ).toFixed(2)
                                        }
                                </span>
                            </Table.Cell>
                            <Table.Cell>
                                R$ {
                                        Number(
                                            recibo.pedido
                                                .reduce((resultado, item) =>
                                                    resultado + (item.oferta.preco * item.demanda)
                                                , 0)
                                                * (recibo.taxa/100)
                                                + recibo.pedido
                                                .reduce((resultado, item) =>
                                                    resultado + (item.oferta.preco * item.demanda)
                                                , 0)
                                                + recibo.entrega
                                        ).toFixed(2)
                                    }
                            </Table.Cell>
                            <Table.Cell>
                                <DadosExtrasDePedido
                                    soBotao={true}
                                    pedidoIdentidade={recibo._id}
                                    entrega={recibo.entrega}
                                    valorEntrega={props.entrega}
                                    refresh={handleRefresh2}
                                />
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell />
                        <Table.HeaderCell>
                            Total:
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            R$ {props.recibos.reduce((totalRecibos, recibo) =>
                                Number(totalRecibos +
                                    recibo.entrega
                                ), 0).toFixed(2)
                            }
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            R$ {props.recibos.reduce((totalRecibos, recibo) =>
                                Number(totalRecibos +
                                        recibo.pedido
                                        .reduce((resultado, item) =>
                                            resultado + (item.oferta.preco * item.demanda)
                                        , 0)
                                ), 0).toFixed(2)
                            }
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            R$ {props.recibos.reduce((totalRecibos, recibo) =>
                                Number(totalRecibos +
                                    recibo.pedido
                                        .reduce((resultado, item) =>
                                            resultado + (item.oferta.preco * item.demanda)
                                        , 0)
                                        * (recibo.taxa/100)
                                ), 0).toFixed(2)
                            }
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            R$ {props.recibos.reduce((totalRecibos, recibo) =>
                                Number(totalRecibos +
                                    recibo.pedido
                                        .reduce((resultado, item) =>
                                            resultado + (item.oferta.preco * item.demanda)
                                        , 0)
                                        * (recibo.taxa/100)
                                        + recibo.pedido
                                        .reduce((resultado, item) =>
                                            resultado + (item.oferta.preco * item.demanda)
                                        , 0)
                                        + recibo.entrega
                                ), 0).toFixed(2)
                            }
                        </Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Footer>
            </Table>
        </Fragment>
    );
}