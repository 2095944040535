import React, {Fragment, useEffect, useState} from "react";
import {Modal, Popup, Table, Card} from 'semantic-ui-react';
import api from "../../services/api";

export default function PedidosConfirmados(props) {

    const [reiniciar, setReiniciar] = useState(false);
    const [mostrar, setMostrar] = useState(false);
    const [modalProdutoProdutor, setModalProdutoProdutor] = useState(false);
    const [modalCategoria, setModalCategoria] = useState(false);
    const [modalPreco, setModalPreco] = useState(false);
    const [modalDisponivel, setModalDisponivel] = useState(false);
    const [modalValorFinalNovo, setModalValorFinalNovo] = useState(false);
    const [modalIdPedido, setModalIdPedido] = useState(false);
    const [modalIdProduto, setModalIdProduto] = useState(false);
    const [modalPedidoNovo, setModalPedidoNovo] = useState(false);
    const [modalPedidoAntigo, setModalPedidoAntigo] = useState(false);

    useEffect(() => {
        props.refresh()
    }, [mostrar, modalPedidoNovo, reiniciar])

    async function handleAlterar(){
        try {
            await props.refresh()
            await api.put('pedidos/alterarum/' + props.pedidoIdentidade,
                        {
                                '_id': modalIdPedido,
                                'demanda': modalPedidoNovo
                            }
                        )
        } catch (e) {
            alert(e)
        }
        setReiniciar(!reiniciar)
    }

    async function handleDeletar(id){
        try {
            await api.patch('pedidos/deletarum/' + props.pedidoIdentidade,
                    {
                            '_id': id
                        }
                    )

             props.refresh()
        }catch (e) {
            alert(e)
        }

    }

    return(
        <Fragment>
            <h1>Administrar os Pedidos de {props.usuario.usuario}</h1>
            <h2>...</h2>
            <h2>para o dia {(props?.dia == null)? '' :
                    Intl.DateTimeFormat("pt-Br", {
                                    year: "numeric",
                                    month: "long",
                                    day: "2-digit"
                                }).format(new Date(props.dia))}</h2>
            <h3>Valor da compra R$ {Number(props.comprado
                                            .reduce((resultado, item) =>
                                                resultado + (item.oferta.preco * item.demanda)
                                            , 0)).toFixed(2)
                                }
            </h3>
            <h3>Valor da taxa de {props.valorTaxa}% : R$ {Number((props.valorTaxa/100) * props.comprado
                                                            .reduce((resultado, item) =>
                                                                resultado + (item.oferta.preco * item.demanda)
                                                            , 0)).toFixed(2)}
            </h3>
            {props?.entrega === 0 ?
                <h3>Sem entregas R$ 0</h3>
            :
                <h3>Valor da entrega R$ {props.entrega}</h3>
            }
            <h3>Valor total R$ {Number(props.entrega + props.comprado
                                            .reduce((resultado, item) =>
                                                resultado + (item.oferta.preco * item.demanda)
                                            , 0) + ((props.valorTaxa/100) * props.comprado
                                                            .reduce((resultado, item) =>
                                                                resultado + (item.oferta.preco * item.demanda)
                                                            , 0))).toFixed(2)
                                }
            </h3>
            <h2>...</h2>
            <h1>Seus Pedidos</h1>
            <div className="meus-pedidos-table-container">
                <Table>
                    <Table.Header>
                        <Table.Row className="meus-pedidos-header-row">
                            <Table.HeaderCell>
                                Produto
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Produtor
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Categoria
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Descrição
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Preço
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Disponível
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Unidade
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Seu Pedido
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Preço Final
                            </Table.HeaderCell>
                            <Table.HeaderCell>

                            </Table.HeaderCell>
                            <Table.HeaderCell>

                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {props.comprado.map(pedido => (
                            <Table.Row className="meus-pedidos-row" key={pedido._id}>
                                <Table.Cell>
                                    <Popup position='right center' content={
                                        <Table.HeaderCell  className="semana-pop">
                                            {/*Alternativamente eu posso deixar a
                                            descrição do produto aqui.*/}
                                            {pedido.oferta.produto_produtor.produto.descricao}
                                        </Table.HeaderCell>
                                    } trigger={<p>{pedido.oferta.produto_produtor.produto.produto}</p>} />
                                </Table.Cell>
                                <Table.Cell>
                                    {pedido.oferta.produto_produtor.produtor.produtor}
                                </Table.Cell>
                                <Table.Cell>
                                    {pedido.oferta.produto_produtor.produto.categoria.categoria}
                                </Table.Cell>
                                <Table.Cell>
                                    {pedido.oferta.produto_produtor.produto.descricao}
                                </Table.Cell>
                                <Table.Cell className="nowrap">
                                    R$ {pedido.oferta.preco}
                                </Table.Cell>
                                <Table.Cell>
                                    {pedido.oferta.oferta - props.diminuirEstoque.filter(produto => produto._idOferta === pedido.oferta._id).map(produto => produto.demandaTotal)}
                                </Table.Cell>
                                <Table.Cell className="nowrap">
                                    {pedido.oferta.produto_produtor.produto.quantia} {pedido.oferta.produto_produtor.produto.unidade.unidade}
                                </Table.Cell>
                                <Table.Cell>
                                    {pedido.demanda}
                                </Table.Cell>
                                <Table.Cell className="nowrap">
                                    R$ {(pedido.oferta.preco * pedido.demanda)}
                                </Table.Cell>
                                <Table.Cell>
                                    <button onClick={() => {
                                        handleDeletar(pedido._id)
                                    }}>
                                        Cancelar Pedido
                                    </button>
                                </Table.Cell>
                                <Table.Cell>
                                    <button onClick={() => {
                                        props.refresh()
                                        setMostrar(true)
                                        setModalProdutoProdutor(<span>{pedido.oferta.produto_produtor.produto.produto} de {pedido.oferta.produto_produtor.produtor.produtor}</span>)
                                        setModalIdPedido(pedido._id)
                                        setModalIdProduto(pedido.oferta._id)
                                        setModalCategoria(pedido.oferta.produto_produtor.produto.categoria.categoria)
                                        setModalPreco(pedido.oferta.preco)
                                        setModalDisponivel((pedido.oferta.oferta - props.diminuirEstoque.filter(produto => produto._idOferta === pedido.oferta._id).map(produto => produto.demandaTotal)))
                                        setModalPedidoNovo(pedido.demanda)
                                        setModalPedidoAntigo(pedido.demanda)
                                        setModalValorFinalNovo((pedido.oferta.preco * pedido.demanda))
                                    }}>
                                        Corrigir
                                    </button>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                        {mostrar && <div className="modaloverlay"/>}
                        <Modal className="modal"
                          open={mostrar}
                          closeOnEscape={true}
                          closeOnDimmerClick={false}
                          //onClose={}
                        >
                          <Card>
                              <Card.Content>
                                <Card.Header>{modalProdutoProdutor}</Card.Header>
                                <Card.Meta>ID-Pedido: {modalIdPedido}</Card.Meta>
                                <Card.Meta>ID-Produto: {modalIdProduto}</Card.Meta>
                                <Card.Meta>Categoria: {modalCategoria}</Card.Meta>
                                <Card.Description>
                                    Preço: R$ {modalPreco}
                                </Card.Description>
                                <Card.Description>
                                    Disponível: {modalDisponivel - (modalPedidoNovo - modalPedidoAntigo)} considerando sua reserva de {modalPedidoNovo}
                                </Card.Description>
                                <Card.Description>
                                    Valor do Pedido: R$ {modalValorFinalNovo}
                                </Card.Description>
                              </Card.Content>
                              <Card.Content extra>
                                  <input
                                      type="number"
                                      placeholder={modalPedidoAntigo}
                                      max={modalDisponivel + modalPedidoAntigo}
                                      min={1}
                                      value={modalPedidoNovo}
                                      onChange={e => {
                                          if (parseFloat(e.target.value) !== modalPedidoNovo) {
                                              if (parseFloat(e.target.value) <= (modalDisponivel + modalPedidoAntigo) && parseFloat(e.target.value) > 0) {
                                                  setModalPedidoNovo(e.target.value)
                                                  setModalValorFinalNovo(e.target.value * modalPreco)
                                              } else {
                                                  return setModalPedidoNovo(1)
                                              }
                                          }
                                      }}
                                  />
                                <div className='ui two buttons'>
                                  <button color='green' onClick={() => {
                                      setMostrar(false)
                                      handleAlterar()
                                  }}>
                                      Alterar
                                      Pedido
                                  </button>
                                  <button color='red' onClick={() => {
                                      setMostrar(false)
                                      setModalProdutoProdutor(false)
                                      setModalIdProduto(false)
                                      setModalIdPedido(false)
                                      setModalCategoria(false)
                                      setModalPreco(false)
                                      setModalDisponivel(false)
                                      setModalPedidoNovo(false)
                                      setModalPedidoAntigo(false)
                                      setModalValorFinalNovo(false)
                                  }}>
                                      Não alterar
                                  </button>
                                </div>
                              </Card.Content>
                            </Card>
                        </Modal>
                    </Table.Body>

                    <Table.Footer>
                        <Table.Row>

                        </Table.Row>
                    </Table.Footer>
                </Table>
            </div>
        </Fragment>
    );
}