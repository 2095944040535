import React, { useState, useEffect } from "react";

import './styles.css';

import NavBar from "../../components/NavBar";
import {useAuth0} from "../../react-auth0-spa";
import spinner from "../../assets/Spinner-1.6s-194px.svg";
import Layout from "../../components/layout";
import {useHistory} from "react-router-dom";
import LogosTopo from "../../components/logosTopo";
import PedidosConfirmados from "./PedidosConfirmados";
import FazerPedidos from "./FazerPedidos";
import DadosExtrasDePedido from "./DadosExtrasDePedido";
import api from "../../services/api";
import ConditionalPedidos from "./ConditionalPedidos";

export default function Pedido() {
    const [dia, setDia] = useState(null);
    const [usuario, setUsuario] = useState([]);
    const [comprado, setComprado] =useState([]);
    const [refresh, setRefresh] = useState(false);
    const [diminuirEstoque, setDiminuirEstoque] = useState([]);
    const [pedidoIdentidade, setPedidoIdentidade] = useState([]);
    const [entrega, setEntrega] = useState(0);
    const [taxa, setTaxa] = useState(0);
    const [valorEntrega, setValorEntrega] = useState(0);
    const [valorTaxa, setValorTaxa] = useState(0);

    const { loading, user } = useAuth0();
    const history = useHistory();

    useEffect(() => {
        document.title = "Pedido - Rede Tamanduá"
    },[])

    useEffect(() => {
        api.get('taxas')
            .then(response => {
                const dados = response.data
                const novoValorEntrega = dados // inserir _id de entrega
                    .filter(dado => dado._id === '5edae6db3533600004c8e215')
                    .map(dado => dado.valor)
                const novoValorTaxa = dados // inserir _id de taxa
                    .filter(dado => dado._id === '5edae6f63533600004c8e216')
                    .map(dado => dado.valor)
                setValorEntrega(novoValorEntrega[0])
                setValorTaxa(novoValorTaxa[0])
            }).catch(e => alert(e));

        try {

            api.get('portas')
                .then(response => {
                    let dataEscolhida = response.data;
                    let dados = dataEscolhida.map(d => d.data)
                    if (dados[0] !== dia) {
                        //console.log('Part.1: Get date')
                        dataEscolhida.map(d => setDia(d.data))
                        //console.log('Part.1: Complete');
                    } else {

                    }
                });

        } catch (e) {
            alert(e);
        }

        try {
            if(!(user == null) && ((usuario === null) || (usuario === undefined || usuario.length === 0))){
                /*console.log('Part.2: Get Usuarios, pois...')
                console.log('!(user == null)', true)
                console.log('user', user)
                console.log('((usuario === null) || (usuario === undefined || usuario.length === 0))', ((usuario === null) || (usuario === undefined || usuario.length === 0)))
                console.log('usuario', usuario)*/
                api.get('usuarios/' + user.sub)
                    .then(response => {
                        setUsuario(response.data)
                        /*console.log('Part.2: Complete')
                        console.log(response.data)*/

                });
            }

        } catch (e) {
            alert(e);
        }

        try {
            if(!(dia == null) && !(usuario?.prossumidor == null)){
                //console.log('Part.3: Get comprados')
                api.get('pedidos/' + dia)
                    .then(response => {
                        const dados = response.data.filter(recibo => recibo.prossumidor._id === usuario.prossumidor)
                            .map(recibo => recibo.pedido)

                        const identidade = response.data.filter(recibo => recibo.prossumidor._id === usuario.prossumidor)
                            .map(recibo => recibo._id)

                        response.data.filter(recibo => recibo.prossumidor._id === usuario.prossumidor)
                            .map(recibo => {
                                setEntrega(recibo.entrega)
                                setTaxa(recibo.taxa)
                            })

                        // flatten data => https://stackoverflow.com/questions/27266550/how-to-flatten-nested-array-in-javascript
                        // reduce and sum data => https://stackoverflow.com/questions/49020000/reduce-multiple-objects-into-one-adding-values-together
                        // reduce and sum actually used => https://gist.github.com/Falnesio/72c4bd5935bfc670080ea69e1b6da512
                        const estoque = response.data
                                .map(recibos => recibos.pedido)

                        //console.log('Dados de soma de demanda', diminuirEstoque)
                        //console.log('Dados desagregado de demanda', response.data.map(recibos => recibos.pedido))
                        //console.log('Dados achatados de demanda', response.data.map(recibos => recibos.pedido).reduce((a,b) => a.concat(b)))

                        if (estoque?.[0] === undefined) {

                        } else {

                            const diminuirEstoque = estoque.reduce((a,b) => a.concat(b))
                                .reduce((resultado, item) => {
                                    const idxFound = resultado
                                        .findIndex(produto => produto._idOferta === item.oferta._id)
                                    if (idxFound !== -1) {
                                        resultado[idxFound].demandaTotal += item.demanda;
                                    } else {
                                        resultado.push({ _idOferta: item.oferta._id, demandaTotal: item.demanda });
                                    }
                                    return resultado
                                }, []);

                            setDiminuirEstoque(diminuirEstoque);
                        }

                        if(dados?.[0] === undefined){

                            /*console.log('----------------')
                            console.log('#comprado.1 = skipped')
                            console.log('dados?.[0] is undefined')
                            console.log('comprado', comprado)
                            console.log('----------------')*/

                        } else {
                            //https://stackoverflow.com/questions/48639336/check-if-two-arrays-contain-identical-objects-react-componentdidupdate
                            if (JSON.stringify(dados[0]) === JSON.stringify(comprado)) {

                                /*console.log('----------------')
                                console.log('#comprado.2 = skipped')
                                console.log('JSON.stringify(dados[0]) == JSON.stringify(comprado)')
                                console.log('comprado', comprado)
                                console.log('dados[0]', dados[0])
                                console.log('----------------')*/

                            } else {
                                /*console.log('----------------')
                                console.log('#comprado.3 = new data')
                                console.log('JSON.stringify(dados[0]) != JSON.stringify(comprado)')
                                console.log('comprado', comprado)
                                console.log('dados[0]', dados[0])
                                console.log('----------------')*/
                                setPedidoIdentidade(identidade[0])
                                setComprado(dados[0]);
                            }
                        }
                        //console.log('Part.3: Complete')
                    })
                    .catch(e => alert(e));
            }
        } catch (e) {
            alert(e);
        }

        //console.log('Deu CERTO!', diminuirEstoque)
    },[ user, dia, usuario, comprado, refresh ] )


    function handleLoading() {
        history.push('/');
    }

    if (loading || !user ) {
        return (
            <div className="carregando">
                Carregando...
                <img src={spinner} alt="loading"/>
                ...se demorar muito é possível
                que não tem autorização ou que
                você esqueceu de entrar na sua
                conta
                <button onClick={() => handleLoading()} type="button" className="buttonloading">
                    Voltar para fazer o login...
                </button>
            </div>

        );
    }

// https://stackoverflow.com/a/35537718/9295348
    async function handleRefresh () {
        setRefresh(!refresh)
    }


    return(
        <Layout>
            <div id="outer-container">

                <NavBar noOverlay pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" }/>

                <main id="page-wrap">
                    <LogosTopo/>
                    <div className="tabela-pedido-container">
                        <ConditionalPedidos usuario={usuario}>
                            <div className='contain-dados-extras'>
                                {comprado[0] !== undefined && usuario?.endereco ?
                                    <div className='dados-extras-de-pedido'>
                                        <DadosExtrasDePedido pedidoIdentidade={pedidoIdentidade} entrega={entrega} valorEntrega={valorEntrega} taxa={taxa} refresh={handleRefresh}/>
                                    </div>
                                :
                                    <div className='dados-extras-de-pedido-escondido'>
                                        É necessário fornecer um endereço na página de perfil e comprar pelo menos um item para obter a opção de entregas.

                                        As entregas estão no valor de R$ {valorEntrega}.
                                    </div>
                                }
                            </div>
                            <PedidosConfirmados user={user} dia={dia} usuario={usuario} comprado={comprado} diminuirEstoque={diminuirEstoque} pedidoIdentidade={pedidoIdentidade} refresh={handleRefresh} entrega={entrega} valorEntrega={valorEntrega} valorTaxa={valorTaxa}/>
                        </ConditionalPedidos>
                        <FazerPedidos user={user} dia={dia} usuario={usuario} comprado={comprado} diminuirEstoque={diminuirEstoque} valorTaxa={valorTaxa} entrega={entrega} refresh={handleRefresh}/>
                    </div>
                </main>
            </div>
        </Layout>
    );
}