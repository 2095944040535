//https://coderrocketfuel.com/article/add-google-analytics-to-a-next-js-and-react-website
// googleAnalytics.js

import ReactGA from "react-ga"



export const initGA = () => {
  ReactGA.initialize("UA-124642262-3")
}

export const logPageView = () => {
  ReactGA.set({
    page: window.location.pathname
  })
  ReactGA.pageview(window.location.pathname)
}
