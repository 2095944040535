import React, { Fragment, useState, useEffect } from 'react';

//Para trabalhar com as datas
import DatePicker, { registerLocale } from "react-datepicker/es";
import pt from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

//Para trabalhar com a tabela
import {Modal, Table} from 'semantic-ui-react';
//import '../../../components/table.css';

//Para trabalhar com o dropdown
import Select from 'react-select';

//Para conectar com o backend
import api from '../../../services/api';

import './styles.css';
import PortasSemana from "./PortasSemana";

registerLocale("pt-BR", pt);

export default function TabelaSemana () {
    //Trabalhar datas
    const [startDate, setStartDate] = useState(null);
    const [dia, setDia] = useState([]);
    const [diaJSON, setDiaJSON] = useState(null);

    //Nova Entrada
    const [novoProdutoProdutor, setNovoProdutoProdutor] = useState({label: 'Escolher Produto'});
    const [novaQuantidade, setNovaQuantidade] = useState([]);
    const [novoPreco, setNovoPreco] = useState([]);

    //Corrigir Dados
    const [mostrar, setMostrar] = useState(false);
    const [idAtual, setIdAtual] = useState([]);
    const [corrigirProduto, setCorrigirProduto] = useState([]);
    const [corrigirPreco, setCorrigirPreco] = useState([]);
    const [corrigirQuantidade, setCorrigirQuantidade] = useState([]);

    //Trabalhar bases de dados
    const [produtosProdutores, setProdutosProdutores] = useState([]);
    const [semana, setSemana] = useState([]);

    //ReiniciarPágina
    const [restart, setRestart] = useState(false);

    //Copiar planilha de outra data
    const [mostrarCopiarData, setMostrarCopiarData] = useState(false);
    const [copiarDate, setCopiarDate] = useState(null);

    useEffect(() => {
        // Formatar data
        try {
            if (!(startDate == null)){
                const dia = Intl.DateTimeFormat("pt-Br", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit"
                }).format(startDate);
                setDia(dia);
                setDiaJSON(JSON.parse(JSON.stringify(startDate)));
            }
        } catch (e) {
            alert("Start date não escolhida" + e);
        }

        //Buscar produtosprodutores
        try {
            api.get('produtosprodutores', {})
                .then(response => {
                    setProdutosProdutores(response.data);
                });
        } catch (e) {
            alert(e);
        }

        //Buscar ofertas para determinada data
        try{
            if (!(diaJSON == null)) {
                api.get('ofertas/' + diaJSON, {})
                .then(response => {
                    setSemana(response.data)
                });
            }
        } catch (e) {
            alert(e);
        }

        //Reiniciar entradas
        setNovoProdutoProdutor({label: 'Escolher Produto'});
        setNovaQuantidade([]);
        setNovoPreco([]);
    }, [startDate, diaJSON, restart]);

//https://www.tutorialspoint.com/javascript/date_getday.htm
    const eQuarta = date => {
        const day = date.getDay();
        return day === 3;
    };

// Funcionamento dos botões
    async function handlecriaroferta(e) {
        e.preventDefault()

        try {
            await api.post('ofertas', {
                "data": diaJSON,
                "preco": novoPreco,
                "oferta": novaQuantidade,
                "produto_produtor": novoProdutoProdutor.value
            }, {

            });

        } catch (e) {
            alert("É necessário preencher tudo e corretamente, (Ex. certifique-se de que preço e quantidade contenham apenas números e de que o produto do produtor já não consta nas ofertas dessa data)  " + e)
        }

        setRestart(!restart);
    }

    async function handlecancelaroferta() {
        try {

        } catch (e) {
            alert(e)
        }

        setNovoProdutoProdutor({label: 'Escolher Produto'});
        setNovaQuantidade([]);
        setNovoPreco([]);
    }

    async function handleDeleteOferta(id){

        try {
            await api.delete(`ofertas/${id}`, {

            });

            setSemana(semana.filter(oferta => oferta._id !== id));
        } catch (id) {
            alert(id)
        }
    }

    async function handleRenomearOferta(id){

        try {
            await api.put(`ofertas/${id}`, {
                "oferta": corrigirQuantidade,
                "preco": corrigirPreco
            });

        } catch (e) {
            alert("Erro:" + e)
        }

        setMostrar(false);
        setIdAtual([]);
        setCorrigirProduto([]);
        setCorrigirPreco([]);
        setCorrigirQuantidade([]);
        setRestart(!restart);
    }

    async function handleCopiarPlanilha() {
        await api.post('ofertas/copiar',{
            "copiarDe": JSON.parse(JSON.stringify(copiarDate)),
            "colocarEm": diaJSON
        }).catch(e => alert(e))

        setCopiarDate(null)
        setMostrarCopiarData(false)
        setRestart(!restart)
    }

//date formatting: https://www.carlrippon.com/formatting-dates-and-numbers-in-react/
    return(
        <Fragment>
            <div className="portas-semana">
                <PortasSemana dia={dia} diaJSON={diaJSON}/>
            </div>
            <h1 className="SemanaTitulotopo"> Administrar a Semana de Entrega</h1>
            <h1 className="SemanaTitulofundo">{startDate == null ? '': ` para o dia ${dia}` }</h1>
            <DatePicker
                dateFormat="dd/MM/yyyy"
                locale="pt-BR"
                selected={startDate}
                filterDate={eQuarta}
                onChange={date => {
                    setStartDate(date);
                }}
                placeholderText="Escolha uma quarta-feira"
            />
            {startDate == null? '':
                <div className="oferta-tables-container">
                    <div className="semana-entrada-container">
                        <Table className="semana-tabela-nova-entrada">
                            <Table.Header>
                                <Table.HeaderCell className="produto-produtor-headercell">
                                    Criar Nova Oferta
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <Select className="produto-categoria-criar"
                                        options = {produtosProdutores.map(produtoProdutor => (
                                             {value: produtoProdutor?._id,
                                              label: `${produtoProdutor?.produto?.produto} - ${produtoProdutor?.produtor?.produtor}`}
                                        ))}
                                        placeholder={novoProdutoProdutor.label}
                                        value={novoProdutoProdutor}
                                        onChange={val => setNovoProdutoProdutor(val)}
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <div>
                                        <input
                                            placeholder='Quanto disponível'
                                            value={novaQuantidade}
                                            onChange={e => setNovaQuantidade(e.target.value)}
                                        />
                                        {novoProdutoProdutor.label === 'Escolher Produto' ? '':
                                            <div className="adicionar-quantidade-inner-cell">
                                                {produtosProdutores
                                                    .filter(produtoProdutor => produtoProdutor._id === novoProdutoProdutor.value)
                                                    .map(produtoProdutor => (
                                                    <p className="adicionar-quantidade-descricao">
                                                        com {produtoProdutor.produto.quantia} {produtoProdutor.produto.unidade.unidade} cada
                                                    </p>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </Table.HeaderCell>
                                <Table.HeaderCell >
                                    <div className="adicionar-preco-inner-cell">
                                        <h2>R$</h2>
                                        <input
                                            placeholder='Preço unitário'
                                            value={novoPreco}
                                            onChange={e => setNovoPreco(e.target.value)}
                                        />
                                    </div>
                                </Table.HeaderCell>
                            </Table.Header>
                            {novoProdutoProdutor.label === 'Escolher Produto' ? '':
                                <Table.Header>
                                    <Table.HeaderCell>
                                        Descrição do Produto:
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        {produtosProdutores.filter(produtoProdutor => produtoProdutor._id === novoProdutoProdutor.value)
                                            .map(produtoProdutor => (
                                                <p>
                                                {produtoProdutor.produto.categoria.categoria} vendidas(os) em porções de {produtoProdutor.produto.quantia} {produtoProdutor.produto.unidade.unidade}.
                                                <br/>
                                                {produtoProdutor.produto.descricao}
                                                </p>
                                        ))}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className="criar-nova-oferta-headercell">
                                        <button className="criar-oferta" onClick={handlecriaroferta}>
                                            Enviar Nova Oferta
                                        </button>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell className="cancelar-nova-oferta-headercell">
                                        <button className="cancelar-oferta" onClick={handlecancelaroferta}>
                                            Cancelar Nova Oferta
                                        </button>
                                    </Table.HeaderCell>
                                </Table.Header>
                            }
                        </Table>
                    </div>
                    <div className="semana-table-resultados">
                        <Table>
                            {semana[0] !== undefined ?
                                <Table.Header>
                                    <Table.Row className="semana-header-row">
                                        <Table.HeaderCell>
                                            ID Oferta
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Quem Produz
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Produto
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Categoria
                                        </Table.HeaderCell>
                                        <Table.HeaderCell className="semana-descricao">
                                            Descrição
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Valor (R$)
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Disponível
                                        </Table.HeaderCell>
                                        <Table.HeaderCell className="semana-unidade">
                                            Unidade
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                            :
                                <div className='copiar-planilha-de-outra-data-container'>
                                    {mostrarCopiarData ?
                                        <div className='copiar-planilha-de-outra-data'>
                                            <h2>
                                                Escolha uma data para copiar:
                                            </h2>
                                            <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                locale="pt-BR"
                                                selected={copiarDate}
                                                filterDate={eQuarta}
                                                onChange={date => {
                                                    setCopiarDate(date);
                                                }}
                                                placeholderText="Escolha uma quarta-feira"
                                            />
                                            {copiarDate ?
                                                <div className='confirmar-copiar-outra-data'>
                                                    <span>

                                                        Copiar a planilha de {Intl.DateTimeFormat("pt-Br", {
                                                                                        year: "numeric",
                                                                                        month: "long",
                                                                                        day: "2-digit"
                                                                                    }).format(copiarDate)
                                                    } para a data de {dia}
                                                    </span>
                                                    <button onClick={() => handleCopiarPlanilha()}>
                                                        Confirmar
                                                    </button>
                                                    <button onClick={() => {
                                                        setMostrarCopiarData(false)
                                                        setCopiarDate(null)
                                                    }}>
                                                        Cancelar
                                                    </button>
                                                </div>
                                            :
                                                <button onClick={() => {
                                                    setMostrarCopiarData(false)
                                                    setCopiarDate(null)
                                                }}>
                                                    Cancelar
                                                </button>
                                            }
                                        </div>
                                        :
                                        <button onClick={() => setMostrarCopiarData(true)}>
                                            <h3>
                                                Copiar a planilha de outra Data
                                            </h3>
                                        </button>
                                    }
                                </div>
                            }

                            <Table.Body className="semana-table-body">
                                {semana?.filter(semana => semana.data === diaJSON).map(oferta => (
                                    <Table.Row key={oferta._id} className="semana-table-row">
                                        <Table.Cell>
                                            {oferta?._id}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {oferta?.produto_produtor?.produtor?.produtor}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {oferta?.produto_produtor?.produto?.produto}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {oferta?.produto_produtor?.produto?.categoria?.categoria}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {oferta?.produto_produtor?.produto?.descricao}
                                        </Table.Cell>
                                        <Table.Cell className="nowrap">
                                            R$ {oferta?.preco}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {oferta?.oferta}
                                        </Table.Cell>
                                        <Table.Cell className="nowrap">
                                            {oferta?.produto_produtor?.produto?.quantia} {oferta?.produto_produtor?.produto?.unidade?.unidade}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <button onClick={() => handleDeleteOferta(oferta._id)} className="deletar-produto">
                                                Deletar
                                            </button>
                                        </Table.Cell>
                                        <Table.Cell className="produto-cell">
                                            <button onClick={() => {
                                                setIdAtual(oferta._id);
                                                setCorrigirProduto(`${oferta.produto_produtor.produto.produto} de ${oferta.produto_produtor.produtor.produtor}`);
                                                setMostrar(true);
                                                setCorrigirPreco(oferta.preco);
                                                setCorrigirQuantidade(oferta.oferta);
                                            }} className="renomear-produto">
                                                Corrigir
                                            </button>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                                {mostrar && <div className="modaloverlay"/>}
                                <Modal className="modal"
                                  open={mostrar}
                                  closeOnEscape={true}
                                  closeOnDimmerClick={false}
                                  //onClose={}
                                >
                                  <Table.Header className="produto-tableheader">
                                      <h2>Corrigir Oferta com ID: {idAtual}</h2>
                                    <Table.Row className="produto-row">
                                        <Table.HeaderCell className="produto-headercell">
                                            {corrigirProduto}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell className="produto-headercell">
                                            Preço: <input
                                                placeholder={corrigirPreco}
                                                value={corrigirPreco}
                                                onChange={e => setCorrigirPreco(e.target.value)}
                                            />
                                        </Table.HeaderCell>
                                        <Table.HeaderCell className="produto-headercell">
                                            Quantidade: <input
                                                placeholder={corrigirQuantidade}
                                                value={corrigirQuantidade}
                                                onChange={e => setCorrigirQuantidade(e.target.value)}
                                            />
                                        </Table.HeaderCell>
                                        <button className="corrigir-produto" onClick={() => handleRenomearOferta(idAtual)}>
                                            <Table.HeaderCell className="produto-headercell">
                                                Enviar Correção
                                            </Table.HeaderCell>
                                        </button>
                                        <button className="cancelar-correcao-produto"
                                                onClick={() => {
                                                    setMostrar(false);
                                                    setIdAtual([]);
                                                    setCorrigirQuantidade([]);
                                                    setCorrigirPreco([]);
                                                    setCorrigirProduto([]);
                                                }}
                                        >
                                            <Table.HeaderCell className="produto-headercell">
                                                Cancelar
                                            </Table.HeaderCell>
                                        </button>
                                    </Table.Row>
                                  </Table.Header>
                                </Modal>
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            }
        </Fragment>
    );
}