import React, {useEffect, useState} from 'react';
import { Table, Modal } from 'semantic-ui-react';
import Select from 'react-select';
import { Skeleton } from '@material-ui/lab';

import api from "../../../services/api";

import './styles.css'
import "../../../components/table.css";




export default function TabelaProdutos () {
    //Base de dados
    const [produtos, setProdutos] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [unidades, setUnidades] = useState([]);

    //Nova Entrada
    const [novaDescricao, setNovaDescricao] = useState([]);
    const [novaQuantia, setNovaQuantia] = useState([]);
    const [novaCategoria, setNovaCategoria] = useState({label: 'Qual Categoria?'});
    const [novaUnidade, setNovaUnidade] = useState({label: 'Qual Unidade?'});
    const [novoProduto, setNovoProduto] = useState([]);

    //Corrigir
    const [corrigirDescricao, setCorrigirDescricao] = useState([]);
    const [corrigirQuantia, setCorrigirQuantia] = useState([]);
    const [corrigirCategoria, setCorrigirCategoria] = useState([]);
    const [corrigirUnidade, setCorrigirUnidade] = useState([]);
    const [corrigirProduto, setCorrigirProduto] = useState([]);
    const [idAtual, setIdAtual] = useState([]);
    const [mostrar, setMostrar] = useState(false);

    //Rerenderizar
    const [restart, setRestart] = useState(false);




    //Use Effect
    useEffect(() => {
    api.get('./produtos', {})
        .then(response => {
            setProdutos(response.data);
        })
    api.get('./categorias', {})
        .then(response => {
            setCategorias(response.data);
        })
    api.get('./unidades', {})
        .then(response => {
            setUnidades(response.data);
        })
    }, [restart]);




    //Functions
    async function handleDeleteProduto(id){

        try {
            await api.delete(`produtos/${id}`, {

            });

            setProdutos(produtos.filter(produto => produto._id !== id));
        } catch (id) {
            alert(id)
        }
    }

    async function handleRenomearProduto(id){
        /*console.log("id: ", id,", produto: ", corrigirProduto,
                ", descricao: ", corrigirDescricao,
                ", quantia: ", corrigirQuantia,
                ", unidade: ", corrigirUnidade,
                ", categoria: ", corrigirCategoria)*/

        try {
            await api.put(`produtos/${id}`, {
                "produto": corrigirProduto,
                "descricao": corrigirDescricao,
                "quantia": corrigirQuantia,
                "unidade": corrigirUnidade.value,
                "categoria": corrigirCategoria.value,
            });

        } catch (e) {
            alert("Erro:" + e)
        }

        setMostrar(false);
        setIdAtual([]);
        setCorrigirProduto([]);
        setCorrigirCategoria([]);
        setCorrigirDescricao([]);
        setCorrigirQuantia([]);
        setCorrigirUnidade([]);
        setRestart(!restart);
    }

    async function handleCreateProduto(e){
        e.preventDefault();

        try {
            //console.log("Descriçao existe? ", !Array.isArray(novaDescricao) && novaDescricao.length !== 0 && novaDescricao.trim().length !==0)
            if(!Array.isArray(novaDescricao) && novaDescricao.length !== 0 && novaDescricao.trim().length !==0) {
                await api.post(`produtos`, {
                    "produto": novoProduto,
                    "descricao": novaDescricao,
                    "quantia": novaQuantia,
                    "unidade": novaUnidade.value,
                    "categoria": novaCategoria.value
                }, {

                });
            } else {
                await api.post(`produtos`, {
                    "produto": novoProduto,
                    "quantia": novaQuantia,
                    "unidade": novaUnidade.value,
                    "categoria": novaCategoria.value
                }, {

                });
            }


        } catch (e) {
            alert("É necessário preencher todos os itens e todos precisam de estarem na forma correta (ex. quantia é um número).")
        }

        setNovoProduto([]);
        setNovaCategoria({label: 'Qual Categoria?'});
        setNovaUnidade({label: 'Qual Unidade?'});
        setNovaQuantia([]);
        setNovaDescricao([]);
        setRestart(!restart);
    }




    return(
        <Table compact celled definition className="produto-table">
            <Table.Header className="produto-tableheader">
                <Table.Row className="produto-row">
                    <Table.HeaderCell className="produto-headercell">
                            Criar Produto
                    </Table.HeaderCell>
                    <Table.HeaderCell className="produto-headercell">
                        <input
                            placeholder='Criar novo produto...'
                            value={novoProduto}
                            onChange={e => setNovoProduto(e.target.value)}
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell className="produto-headercell">
                        <input
                            placeholder='Descrição...'
                            value={novaDescricao}
                            onChange={e => setNovaDescricao(e.target.value)}
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell className="produto-headercell-quantia">
                        <input
                            placeholder='Quanto em uma unidade...'
                            value={novaQuantia}
                            onChange={e => setNovaQuantia(e.target.value)}
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell className="produto-headercell">
                         <Select className="produto-categoria-criar"
                                 options = {unidades.map(unidade => (
                                     {value: unidade._id, label: unidade.unidade}
                                 ))}
                                 placeholder={novaUnidade.label}
                                 value={novaUnidade}
                                 onChange={val => setNovaUnidade(val)}
                        />
                    </Table.HeaderCell>
                    <Table.HeaderCell className="produto-headercell">
                         <Select className="produto-categoria-criar"
                                 options = {categorias.map(categoria => (
                                     {value: categoria._id, label: categoria.categoria}
                                 ))}
                                 placeholder={novaCategoria.label}
                                 value={novaCategoria}
                                 onChange={val => setNovaCategoria(val)}
                        />
                    </Table.HeaderCell>
                    <button className="criar-produto" onClick={handleCreateProduto}>
                        <Table.HeaderCell className="produto-headercell">
                            Enviar Produto
                        </Table.HeaderCell>
                    </button>
              </Table.Row>
            </Table.Header>




            <Table.Header className="produto-tableheader">
                <Table.Row className="produto-row">
                    <Table.HeaderCell className="produto-headercell">ID</Table.HeaderCell>
                    <Table.HeaderCell className="produto-headercell">Produto</Table.HeaderCell>
                    <Table.HeaderCell className="produto-headercell">Descrição</Table.HeaderCell>
                    <Table.HeaderCell className="produto-headercell-quantia">Quantia</Table.HeaderCell>
                    <Table.HeaderCell className="produto-headercell">Unidade</Table.HeaderCell>
                    <Table.HeaderCell className="produto-headercell">Categoria</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body className="produto-tablebody">
                {produtos.map(produto => (
                    <Table.Row className="produto-row">
                        {!(produto?._id === undefined) ?
                            <Table.Cell className="produto-cell">{produto._id}</Table.Cell>
                            :
                            <Table.Cell className="produto-cell"><Skeleton width={100} height={50} animation="wave"/></Table.Cell>
                        }
                        {!(produto?.produto === undefined) ?
                            <Table.Cell className="produto-cell">{produto.produto}</Table.Cell>
                            :
                            <Table.Cell className="produto-cell"><Skeleton width={100} height={50} animation="wave"/></Table.Cell>
                        }
                        {!(produto?.descricao === undefined) ?
                            <Table.Cell className="produto-cell">{produto.descricao}</Table.Cell>
                            :
                            <Table.Cell className="produto-cell"><Skeleton width={100} height={50} animation="wave"/></Table.Cell>
                        }
                        {!(produto?.quantia === undefined) ?
                            <Table.Cell className="produto-cell-quantia">{produto.quantia}</Table.Cell>
                            :
                            <Table.Cell className="produto-cell"><Skeleton width={100} height={50} animation="wave"/></Table.Cell>
                        }
                        {!(produto?.unidade === undefined) ?
                            <Table.Cell className="produto-cell">
                                {
                                    <p>{produto.unidade.unidade}</p>
                                }
                            </Table.Cell>
                            :
                            <Table.Cell className="produto-cell"><Skeleton width={100} height={50} animation="wave"/></Table.Cell>
                        }
                        {!(produto?.categoria === undefined) ?
                            <Table.Cell className="produto-cell">
                                {
                                    <p>{produto.categoria.categoria}</p>
                                }
                            </Table.Cell>
                            :
                            <Table.Cell className="produto-cell"><Skeleton width={100} height={50} animation="wave"/></Table.Cell>
                        }
                        <Table.Cell>
                            <button onClick={() => handleDeleteProduto(produto._id)} className="deletar-produto">
                                Deletar
                            </button>
                        </Table.Cell>
                        <Table.Cell className="produto-cell">
                            <button onClick={() => {
                                setIdAtual(produto._id);
                                setMostrar(true);
                                setCorrigirProduto(produto.produto);
                                setCorrigirQuantia(produto.quantia);
                                setCorrigirDescricao(produto?.descricao);
                                setCorrigirCategoria({
                                    value: produto.categoria._id,
                                    label: produto.categoria.categoria,
                                });
                                setCorrigirUnidade({
                                    value: produto.unidade._id,
                                    label: produto.unidade.unidade
                                });
                            }} className="renomear-produto">
                                Corrigir
                            </button>
                        </Table.Cell>
                    </Table.Row>
                ))}
                {mostrar && <div className="modaloverlay"/>}
                <Modal className="modal"
                  open={mostrar}
                  closeOnEscape={true}
                  closeOnDimmerClick={false}
                  //onClose={}
                >
                  <Table.Header className="produto-tableheader">
                      <h2>Corrigir Produto com ID: {idAtual}</h2>
                    <Table.Row className="produto-row">
                        <Table.HeaderCell className="produto-headercell">

                        </Table.HeaderCell>
                        <Table.HeaderCell className="produto-headercell">
                            Produto:
                            <input
                                placeholder={corrigirProduto}
                                value={corrigirProduto}
                                onChange={e => setCorrigirProduto(e.target.value)}
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell className="produto-headercell">
                            Descrição:
                            <input
                                placeholder={corrigirDescricao}
                                value={corrigirDescricao}
                                onChange={e => setCorrigirDescricao(e.target.value)}
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell className="produto-headercell-quantia">
                            Quantia:
                            <input className="produto-input-quantia"
                                placeholder={corrigirQuantia}
                                value={corrigirQuantia}
                                onChange={e => setCorrigirQuantia(e.target.value)}
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell className="produto-headercell">
                            Unidade:
                             <Select className="produto-categoria-criar"
                                     options = {unidades.map(unidade => (
                                         {value: unidade._id, label: unidade.unidade}
                                     ))}
                                     placeholder={corrigirUnidade.label}
                                     onChange={val => setCorrigirUnidade({value: val.value})}
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell className="produto-headercell">
                            Categoria:
                             <Select className="produto-categoria-criar"
                                     options = {categorias.map(categoria => (
                                         {value: categoria._id, label: categoria.categoria}
                                     ))}
                                     placeholder={corrigirCategoria.label}
                                     onChange={val => setCorrigirCategoria({value: val.value})}
                            />
                        </Table.HeaderCell>
                        <button className="corrigir-produto" onClick={() => handleRenomearProduto(idAtual)}>
                            <Table.HeaderCell className="produto-headercell">
                                Enviar Correção
                            </Table.HeaderCell>
                        </button>
                        <button className="cancelar-correcao-produto"
                                onClick={() => {
                                    setMostrar(false);
                                    setIdAtual([]);
                                    setCorrigirProduto([]);
                                    setCorrigirCategoria([]);
                                    setCorrigirDescricao([]);
                                    setCorrigirQuantia([]);
                                    setCorrigirUnidade([]);
                                }}
                        >
                            <Table.HeaderCell className="produto-headercell">
                                Cancelar
                            </Table.HeaderCell>
                        </button>
                    </Table.Row>
                  </Table.Header>
                </Modal>
            </Table.Body>

            <Table.Footer>
                <Table.Row>

                </Table.Row>
            </Table.Footer>
        </Table>
    );
}