import React, { Fragment, useState, useEffect } from "react";
import Toggle from 'react-toggle';
import api from '../../services/api';
import './styles.css'

export default function DadosExtrasDePedido (props) {
    const [restart, setRestart] = useState(false);

    useEffect(() => {
    }, [restart])

    async function handleEntrega() {
        try {
            if (props?.entrega !== 0 && props?.entrega !== undefined && props?.entrega !== null) {
                await api.put('/pedidos/alterarEntrega/' + props.pedidoIdentidade, {
                    "entrega": 0
                }).catch(e => alert(e))
                props.refresh()
            } else {
                await api.put('/pedidos/alterarEntrega/' + props.pedidoIdentidade, {
                    "entrega": props.valorEntrega
                }).catch(e => alert(e))
                props.refresh()
            }
        } catch (e) {
            alert(e)
        }
    }

    return(
        <Fragment>
            {props?.pedidoIdentidade ?
                <label className='switch-entrega'>
                    {props?.soBotao ?
                        <Toggle onClick={() => handleEntrega()} checked={props?.entrega !== 0 && props?.entrega !== undefined && props?.entrega !== null} />
                    :
                        <div>
                            <h2>Gostaria de entrega?</h2>
                            <h3>No valor de R$ {props.valorEntrega}</h3>
                            <Toggle onClick={() => handleEntrega()} checked={props?.entrega !== 0 && props?.entrega !== undefined && props?.entrega !== null} />
                        </div>
                    }
                </label>
            : ''}
        </Fragment>
    );
}