import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Login from "./pages/Login";
import Principal from "./pages/Principal";
import Analise from "./pages/Analise";
import Blog from "./pages/Blog";
import HistoricoProssumidor from "./pages/HistoricoProssumidor";
import HistoricoProdutor from "./pages/HistoricoProdutor";
import Pedido from "./pages/Pedido";
import Administrar from "./pages/Administrar";
import Categorias from "./pages/Administrar/Categorias";
import Hortas from "./pages/Administrar/Hortas";
import Produtores from "./pages/Administrar/Produtores";
import Taxas from "./pages/Administrar/Taxas";
import Produtos from "./pages/Administrar/Produtos";
import Semana from "./pages/Administrar/Semana";
import Unidades from "./pages/Administrar/Unidades";
import Usuarios from "./pages/Administrar/Usuarios";
import Classes from "./pages/Administrar/Classes";
import PrivacyPolicy from "./pages/PrivacyPolicy";


export default function Routes() {
    return(
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Login}/>
                <Route path="/principal" exact component={Principal}/>
                <Route path="/analise" exact component={Analise}/>
                <Route path="/blog" exact component={Blog}/>
                <Route path="/historicoprodutor" exact component={HistoricoProdutor}/>
                <Route path="/historicoprossumidor" exact component={HistoricoProssumidor}/>
                <Route path="/pedido" exact component={Pedido}/>
                <Route path="/administrar" exact component={Administrar}/>
                <Route path="/administrar/categorias" exact component={Categorias}/>
                <Route path="/administrar/hortas" exact component={Hortas}/>
                {/*<Route path="/administrar/produtores" exact component={Produtores}/>*/}
                <Route path="/administrar/taxas" exact component={Taxas}/>
                <Route path="/administrar/produtos" exact component={Produtos}/>
                <Route path="/administrar/semana" exact component={Semana}/>
                <Route path="/administrar/unidades" exact component={Unidades}/>
                <Route path="/administrar/usuarios" exact component={Usuarios}/>
                {/*<Route path="/administrar/classes" exact component={Classes}/>*/}
                <Route path="/privacypolicy" exact component={PrivacyPolicy}/>
            </Switch>
        </BrowserRouter>
    )
}