import React from "react";

import './styles.css';

import Traduzir from "./Traduzir";
import NavBar from "../../components/NavBar";
import Layout from "../../components/layout";
import LogosTopo from "../../components/logosTopo";

export default function PrivacyPolicy() {

    return(
        <Layout>
            <div id="outer-container">

                <NavBar noOverlay pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" }/>

                <main id="page-wrap">
                    <LogosTopo/>
                    <div className="privacypolicy-container">
                        <section className="text">
                            <Traduzir />
                        </section>
                    </div>

                </main>
            </div>
        </Layout>
    );
}