import React from "react";

import './styles.css';

import nagoCasa from "../assets/NAGO-logo-casa.png";
import logoRede from "../assets/redeLogo3.png";
import ufjf from "../assets/logo_ufjf_campus_gv.png";
import cat from "../assets/cat-38.png";

const LogosTopo = () => {
    return (
        <div className="logos-laterais">
            <img src={cat} alt="CAT" width={100}/>
            <img src={nagoCasa} alt="Nagô" width={100}/>
            <img src={ufjf} alt="UFJF" width={200}/>
            <img src={logoRede} alt="UFJF" width={200}/>
        </div>
    );
}

export default LogosTopo;