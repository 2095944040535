import React from 'react';

import "./global.css"

import Routes from "./routes";


function App() {

    
    const condition = new RegExp('loja\.redetamandua');
    if (condition.test(window.location.href)){
        window.location.href = "https://frontend-redeprossumidores.herokuapp.com" + window.location.pathname
    }

    return (
        <Routes/>
    );
}

export default App;
