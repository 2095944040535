import axios from 'axios';

const apiUrl = 'https://backend-redeprossumidores.herokuapp.com';
//utilize o apiUrl abaixo para testar no localhost
//const apiUrl = 'http://localhost:5000'


const api = axios.create({
    baseURL: apiUrl
});

/*
const usuarioid = user.id;
const usuarionome = user.username;

api.post('/usuarios', {
"id": user.id,
"usuario": usuarionome,
});*/

export default api;