import React, { Fragment, useState, useEffect } from "react";
import api from '../../../services/api';
import { Table, Popup } from "semantic-ui-react";
import './styles.css'

export default function PortasSemana (props) {
    const [portas, setPortas] = useState([]);
    const [restart, setRestart] = useState(false);

    useEffect(() => {
        api.get('portas')
            .then(response => {
                setPortas(response.data);
            });

    }, [restart])

    async function handleAbrirPorta(JSON) {

        try {
            await api.post('portas', {
                data: JSON.props.diaJSON,
                aberta: true
            }, {

            });

            setRestart(!restart);
        } catch (e) {
            alert(
                "Precisa selecionar uma data para o próximo dia de entrega primeiro!\n" +
                "Caso as portas já estejam abertas, não tem como abrir uma porta já aberta.\n" +
                 e
            );
        }
    }

    async function handleFecharPorta(id) {

        try {
            await api.delete(`portas/${id}`, {

            }, {

            });

            setPortas(portas.filter(porta => porta._id !== id));
        } catch (e) {
            alert(e);
        }
    }

    return(
        <Fragment>
            <Table>
                <Table.Header>
                    <Table.Row className="portas-texto">
                        <Popup content={
                            <Table.HeaderCell  colSpan='3' className="semana-pop">
                                Depois de selecionar um dia, clique em
                                "Abrir as Portas da Rede para o dia {props.dia}!"
                                para disponibilizar os produtos  escolhidos para
                                a próxima quarta-feira! Clique em "Fechar as
                                Portas" para encerrar a coleta dos pedidos.
                            </Table.HeaderCell>
                        } trigger={<p>Coloque o mouse nesse X</p>} />

                    </Table.Row>
                    <Table.HeaderCell colSpan='3'>
                        <button  onClick={() => handleAbrirPorta({props})}>
                            <h4>Abrir as Portas da Rede para o dia {props.dia}!</h4>
                        </button>
                    </Table.HeaderCell>
                    {(portas.length === 0) ? '':
                        <Table.Row>
                            <Table.HeaderCell colSpan='3'>
                                Portas abertas referente ao dia:
                            </Table.HeaderCell>
                        </Table.Row>
                    }
                </Table.Header>
                <Table.Body>
                    {portas.map(porta => (
                        <Table.Row>
                            <Table.Cell>
                                {Intl.DateTimeFormat("pt-Br", {
                                    year: "numeric",
                                    month: "long",
                                    day: "2-digit"
                                }).format(new Date(porta.data))}
                            </Table.Cell>
                            <Table.Cell>
                                <button onClick={() => {
                                    handleFecharPorta(porta._id)
                                }}>
                                    Fechar as Portas!
                                </button>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Fragment>
    );
}