import React from "react";
import { useAuth0 } from "../react-auth0-spa";
import { Link, useHistory } from "react-router-dom";
import { bubble as Menu } from 'react-burger-menu';
import { FaHandshake, FaAddressCard } from "react-icons/fa";
import { GiHand, TiArrowBack, GiBookmarklet, GiReceiveMoney, GiFarmer, GiTeacher, GiInjustice, GiField, GiCorn, RiBodyScanLine, GoKey } from "react-icons/all";

import "./styles.css"
import spinner from "../assets/Spinner-1.6s-194px.svg";

const NavBarAdmin = () => {
  const { isAuthenticated, loginWithRedirect, logout, loading, user } = useAuth0();
    const history = useHistory();

    function handleLoading() {
        history.push('./');
    }

    if (loading || !user) {
        return (
            <div className="carregando">
                Carregando...
                <img src={spinner} alt="loading"/>
                ...se demorar muito é possível
                que não tem autorização ou que
                você esqueceu de entrar na sua
                conta
                <button onClick={() => handleLoading()} type="button" className="buttonloading">
                    Voltar para fazer o login...
                </button>
            </div>

        );
    }

  return (
    <Menu>
      {!isAuthenticated && (
        <Link className="menu-item" onClick={() => loginWithRedirect({})}><pre className="tab"><FaAddressCard/>     Entrar / Cadastro</pre></Link>
      )}

      {isAuthenticated && <Link className="menu-item" onClick={() => logout()}><pre className="tab"><GiHand/>     Sair</pre></Link>}

      {isAuthenticated &&
          <Link id="home" className="menu-item" to="/">
            <pre className="tab"><TiArrowBack/>     Voltar</pre>
          </Link>
      }
      {isAuthenticated &&
          <Link id="Administrar" className="menu-item" to="/administrar">
            <pre className="tab"><GiBookmarklet/>     Administrar</pre>
          </Link>
      }
      {/*{isAuthenticated &&
          <Link id="Usuarios" className="menu-item" to="/administrar/classes">
            <pre className="tab"><GoKey/>     Classes</pre>
          </Link>
      }*/}
      {isAuthenticated &&
          <Link id="Usuarios" className="menu-item" to="/administrar/usuarios">
            <pre className="tab"><RiBodyScanLine/>     Usuários</pre>
          </Link>
      }
      {isAuthenticated &&
          <Link id="Categorias" className="menu-item" to="/administrar/categorias">
            <pre className="tab"><GiTeacher/>     Categorias</pre>
          </Link>
      }
      {isAuthenticated &&
          <Link id="Unidades" className="menu-item" to="/administrar/unidades">
            <pre className="tab"><GiInjustice/>     Unidades</pre>
          </Link>
      }
      {isAuthenticated &&
          <Link id="Produtos" className="menu-item" to="/administrar/produtos">
            <pre className="tab"><GiCorn/>     Produtos</pre>
          </Link>
      }
      {/*{isAuthenticated &&
          <Link id="Produtores" className="menu-item" to="/administrar/produtores">
            <pre className="tab"><GiFarmer/>     Produtores</pre>
          </Link>
      }*/}
      {isAuthenticated &&
          <Link id="Hortas" className="menu-item" to="/administrar/hortas">
            <pre className="tab"><GiField/>     Hortas</pre>
          </Link>
      }
      {isAuthenticated &&
          <Link id="Semana" className="menu-item" to="/administrar/semana">
            <pre className="tab"><FaHandshake/>     Semana</pre>
          </Link>
      }
      {isAuthenticated &&
          <Link id="Taxas" className="menu-item" to="/administrar/taxas">
            <pre className="tab"><GiReceiveMoney/>     Taxas</pre>
          </Link>
      }
    </Menu>
  );
};

export default NavBarAdmin;